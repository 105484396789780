import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import { ClientDetails } from '../apis/entities/clientDetails.entity'
import { AdminAPI } from '../apis/AdminAPI'
import Modal from 'react-modal'
import Papa from 'papaparse'
import Svg from '../components/Svg'
import { UserDetails, UserInvite } from '../apis/entities/user.entity'
import LoadingIndicator from '../components/LoadingIndicator'
import { useLoadingContext } from '../context/LoadingProvider'
import { isEmail } from '../utils/stringUtils'
import { toastError, toastSuccess } from '../utils/toast'

export default function Client() {
  const { isLoading, setIsLoading } = useLoadingContext()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('')
  const location = useLocation()
  const lastPath = location.pathname.split('/').pop()
  const orgId = lastPath || ''

  const [clientDetails, setClientDetails] = useState<ClientDetails | undefined>()
  const [currentUsers, setCurrentUsers] = useState<UserDetails[]>([])
  const [inviteUsers, setInviteUsers] = useState<UserInvite[]>([])
  const [isInviteDone, setIsInviteDone] = useState<boolean>(false)
  const [inviteStatus, setInviteStatus] = useState<string[] | undefined>([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const btnFileInput = useRef<HTMLInputElement>(null)
  const [reloadCount, setReloadCount] = useState(0)
  enum ModalMode {
    Delete,
    Invite
  }
  const [modalMode, setModalMode] = useState(ModalMode.Delete)
  Modal.setAppElement('#root')

  useEffect(() => {
    const fetchData = async () => {
      const data = await AdminAPI.getClient(orgId)
      setClientDetails(data)
    }
    setIsLoading(true)
    fetchData().finally(() => {
      setIsLoading(false)
    })
  }, [orgId, reloadCount, setIsLoading])

  const reload = /*useCallback(async*/ async () => {
    console.log('reload...')
    // await new Promise(resolve => setTimeout(resolve, 1000));
    setReloadCount(reloadCount + 1)
  } /*, [])*/

  const handleDeleteClicked = (user: UserDetails) => {
    console.log(`handle delete, ${user}`)
    setCurrentUsers([user])
    setModalMode(ModalMode.Delete)
    openModal()
  }
  const handleConfirmDeleteUsers = async () => {
    console.log(`handle delete users, ${currentUsers}`)
    const deleteIds = currentUsers.map((item, i) => {
      return item.id
    })
    const deleteAuth0Ids = currentUsers.map((item, i) => {
      return item.auth0Id
    })
    setIsLoading(true)
    await AdminAPI.deleteUsers(deleteIds, deleteAuth0Ids)
      .then(() => {
        toastSuccess(`User ${email} has been deleted`)
      })
      .catch(error => {
        toastError(`${error}`)
      })
      .finally(() => {
        setIsLoading(false)
        closeModal()
        reload()
      })
    // todo: toast UI say "user is deleted" and remove user from table

    // const deletedUsers = await AdminAPI.deleteUsers(deleteIds)
  }
  const handleConfirmBulkInviteUsers = async () => {
    console.log(`handle confirm invite users:`, inviteUsers)

    setIsLoading(true)
    const results = await AdminAPI.inviteOrgUsers(
      inviteUsers.map(item => {
        return {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          role: item.role,
          orgId: orgId
        }
      })
    ).finally(() => {
      setIsInviteDone(true)
      setIsLoading(false)
    })
    console.log('inviteOrgUsers', results)
    setInviteStatus(
      (results.data?.inviteUsers as any[])?.map((item, i) => {
        if (item.success) {
          return 'Invite sent'
        } else {
          return item.message
        }
      })
    )
  }

  const handleInviteClicked = () => {
    if (!isEmail(email)) {
      toastError('Please input a correct email')
      return
    }
    if (!role) {
      toastError('Please choose a role')
      return
    }
    console.log(`handle invite`, email, role)

    AdminAPI.inviteOrgUser({
      email: email,
      role: role,
      firstName: firstName,
      lastName: lastName,
      orgId: clientDetails?.id
    })

      .then(() => {
        toastSuccess(`Invitation has been sent to ${email}`)
        reload()
      })
      .catch(error => {
        toastError(`${error}`)
      })
  }
  // const handleEditClicked = (id: string) => {
  //   console.log(`handle edit, ${id}`)
  // }
  const handleChooseFileClicked = () => {
    console.log('handleChooseFileClicked')
    setIsInviteDone(false)
    setInviteStatus([])
    btnFileInput.current!.value = ''
    btnFileInput.current?.click()
  }
  const fileChangeHandler = (event: any) => {
    console.log('fileChangeHandler')
    // console.log(event.target.files[0])
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        try {
          const parsedUsers = results.data.map<UserInvite>(item => {
            const row: any = item
            return {
              firstName: row['first name'],
              lastName: row['last name'],
              email: row['email'],
              role: row['role']
            }
          })
          setInviteUsers(parsedUsers)
          console.log('parsedUsers', parsedUsers)
          setModalMode(ModalMode.Invite)
          openModal()
        } catch (error) {
          console.log('parse error', error)
        }
      }
    })
  }

  // Modal
  // const customStyles = {
  //   content: {
  //     top: '50%',
  //     left: '50%'
  //   }
  // }
  const openModal = () => {
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false)
    reload()
  }
  const afterOpenModal = () => {
    // document.body.style.overflow = 'unset';
  }
  const afterCloseModal = () => {
    // document.body.style.overflow = 'auto';
  }

  const renderUserRows = () => {
    return clientDetails?.users?.map((item, i) => {
      return (
        <tr key={item.id}>
          {/* <td>
            <input
              type="checkbox"
              value=""
              className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
            />
          </td> */}
          <td>
            {item.auth0user?.given_name || 'N/A'} {item.auth0user?.family_name || ''}
          </td>
          <td>{item.email || '(email not available)'}</td>
          <td>{item.role}</td>
          <td>{item.status}</td>
          <td>
            {/* <button
              type="button"
              className="mb-2 rounded-lg bg-purple-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">
              Edit Access
            </button> */}
          </td>
          <td>
            <button
              type="button"
              onClick={() => {
                handleDeleteClicked(item)
              }}
              className="my-1 rounded-lg bg-purple-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">
              Delete User
            </button>
          </td>
        </tr>
      )
    })
  }

  return (
    <SidebarLayout>
      {/* page content */}
      <div className="flex grow flex-col gap-8 p-16">
        <div className="flex flex-col items-start gap-8">
          <div className="page-title">{isLoading ? clientDetails?.name : clientDetails?.name}</div>

          {/* invite */}
          <div className="section-heading">Invite a new user</div>
          <div className="flex flex-row gap-4">
            <input
              type="text"
              id="firstName"
              className="w-80 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-100 "
              placeholder="First name"
              onChange={e => setFirstName(e.target.value)}
              required></input>
            <input
              type="text"
              id="lastName"
              className="w-80 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-100 "
              placeholder="Last name"
              onChange={e => setLastName(e.target.value)}
              required></input>
          </div>
          <div className="flex flex-row gap-4">
            <input
              type="text"
              id="email"
              className="w-96 rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-100 "
              placeholder="email"
              onChange={e => setEmail(e.target.value)}
              required></input>

            <select
              className="rounded-lg border-r-[12px] border-purple-600 bg-purple-700 pl-4 pr-1 text-sm font-medium text-white hover:border-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              onChange={e => setRole(e.target.value)}
              defaultValue="">
              <option value={''} disabled hidden>
                Role
              </option>
              <option value={'LearningOps'}>LearningOps</option>
              <option value={'OrgAdmin'}>Org Admin</option>
              <option value={'Admin'}>Admin</option>
              <option value={'SuperAdmin'}>SuperAdmin</option>
            </select>

            <button
              type="button"
              className="rounded-lg bg-purple-700 px-5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              onClick={handleInviteClicked}>
              Send invitation
            </button>
          </div>

          <div className="relative flex w-96 items-center py-5">
            <div className="flex-grow border-t border-gray-400"></div>
            {/* <span className="flex-shrink mx-4 text-gray-400">Content</span> */}
            <div className="flex-grow border-t border-gray-400"></div>
          </div>

          {/* <Dropdown label="Dropdown button" dismissOnClick={true} className='bg-transparent'>
            <Dropdown.Item>Dashboard</Dropdown.Item>
            <Dropdown.Item>Settings</Dropdown.Item>
            <Dropdown.Item>Earnings</Dropdown.Item>
            <Dropdown.Item>Sign out</Dropdown.Item>
          </Dropdown> */}

          {/* <button data-popover-target="popover-default" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Default popover</button>
          <div data-popover id="popover-default" role="tooltip" className="absolute z-10 invisible inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800">
              <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
                  <h3 className="font-semibold text-gray-900 dark:text-white">Popover title</h3>
              </div>
              <div className="px-3 py-2">
                  <p>And here's some amazing content. It's very engaging. Right?</p>
              </div>
              <div data-popper-arrow></div>
          </div> */}

          {/* upload */}
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={fileChangeHandler}
            className="hidden"
            ref={btnFileInput}
          />
          <div className="section-heading">Bulk invite new users</div>
          <div>
            {/* divider */}

            <button
              className="w-auto rounded-lg bg-purple-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
              onClick={handleChooseFileClicked}>
              Choose a csv file
            </button>
          </div>
        </div>
        <div></div>
        <div></div>
        <div className="section-heading text-left">Client User List</div>
        <table className="table-auto text-left text-white">
          <thead>
            <tr>
              {/* <th></th> */}
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>{renderUserRows()}</tbody>
        </table>
        {isLoading && (
          <div className="flex items-center justify-center">
            <LoadingIndicator />
          </div>
        )}
        {/* prevent overflow scroll clipping empty area */}
        <div className="mt-20">&nbsp;</div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onAfterClose={afterCloseModal}
        onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Modal"
        className="Modal relative"
        overlayClassName="Overlay"
        closeTimeoutMS={250}>
        {/* close button */}
        <div className="flex justify-end">
          <button className="w-6" onClick={closeModal}>
            <Svg icon="close" />
          </button>
        </div>
        {/* content */}
        {modalMode === ModalMode.Delete && (
          <>
            <div className="flex w-96 flex-col gap-4">
              <div className="text-left font-medium">
                Delete user '{currentUsers.length > 0 ? currentUsers[0].email : ''}'?
              </div>
              <div>Deleted user won't be able to access the Insight platform</div>
              <div className="mt-4 flex justify-end gap-6">
                <button
                  className="mb-2 rounded-lg px-5 py-2.5 text-sm font-medium text-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:focus:ring-purple-900"
                  onClick={closeModal}>
                  Cancel
                </button>
                <button
                  className="mb-2 rounded-lg bg-purple-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
                  onClick={handleConfirmDeleteUsers}>
                  Delete
                </button>
              </div>
            </div>
          </>
        )}
        {modalMode === ModalMode.Invite && (
          <>
            <div className="flex max-h-[70vh] w-96 w-[70vw] flex-col gap-4 overflow-auto">
              <div className="text-left font-medium">
                {isInviteDone ? 'Bulk invitation done' : 'Please confirm inviting the following users:'}
              </div>
              <table className="table-auto text-left">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {inviteUsers.map((inviteUser, i) => {
                    return (
                      <tr key={`inviteUser-${i}`}>
                        <td>
                          {inviteUser.firstName || 'N/A'} {inviteUser.lastName || ''}
                        </td>
                        <td>{inviteUser.email || '(email not available)'}</td>
                        <td>{inviteUser.role}</td>
                        <td>
                          {inviteStatus && inviteStatus.length > i
                            ? inviteStatus![i]
                            : inviteUser.email
                            ? 'To be invited'
                            : ''}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className="mt-4 flex justify-end gap-6">
              <button
                className={
                  (isInviteDone ? 'hidden ' : '') +
                  'mb-2 rounded-lg px-5 py-2.5 text-sm font-medium text-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:focus:ring-purple-900'
                }
                onClick={closeModal}>
                Cancel
              </button>
              <button
                disabled={isInviteDone || isLoading}
                className={
                  'disabled ' +
                  (isInviteDone ? 'hidden ' : '') +
                  'mb-2 rounded-lg bg-purple-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900'
                }
                onClick={handleConfirmBulkInviteUsers}>
                Send Invitations
              </button>
              <button
                className={
                  (isInviteDone ? '' : 'hidden ') +
                  'mb-2 rounded-lg bg-purple-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-1 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900'
                }
                onClick={closeModal}>
                OK
              </button>
            </div>
            {isLoading && <LoadingIndicator />}
          </>
        )}
      </Modal>
    </SidebarLayout>
  )
}
