import { createContext, useContext, useState, ReactNode } from 'react'

interface LoadingContextProps {
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
}

const LoadingContext = createContext<LoadingContextProps | null>(null)

export const useLoadingContext = (): LoadingContextProps => {
  const loadingContext = useContext(LoadingContext)
  if (!loadingContext) {
    throw new Error('loading context must be inside a provider')
  }
  return loadingContext
}

type Props = {
  children: ReactNode
}
export const LoadingProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const providerValue = {
    isLoading,
    setIsLoading
  }

  return <LoadingContext.Provider value={providerValue}>{children}</LoadingContext.Provider>
}
