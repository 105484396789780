import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import LoadingIndicator from '../components/LoadingIndicator'
import SidebarLayout from '../components/SidebarLayout'
import { Program } from '../apis/entities/program.entity'
import { EoiAPI } from '../apis/EoiAPI'
import sortSvg from '../images/sort.svg'
import sort2Svg from '../images/sort2.svg'
import noteSvg from '../images/note.svg'
import { Tooltip } from 'react-tooltip'
import {
  alternateStyle,
  Direction,
  DirectionAsc,
  DirectionDesc,
  tooltipStyle,
  tooltipStyleReverse
} from '../utils/TableUtils'
import SearchInput from '../components/SearchInput'
import ModalEoiAnswerDetails from '../modals/ModalEoiAnswerDetails'
import DropDownMenu, { DropDownItem } from '../components/DropDownMenu'
import { LearnerReport } from '../apis/entities/learnerReport.entity'
import { toastError, toastInfo, toastSuccess } from '../utils/toast'
import { useAuth } from '../context/AuthProvider'
import Checkbox, { CheckboxSize, CheckboxTheme } from '../components/Checkbox'
import { useParams } from 'react-router-dom'
import ModalEoiInviteOrReject from '../modals/ModalEoiInviteOrReject'
import Emitter, { Events } from '../core/emitter'
import ModalEoiConfirmation from '../modals/ModalEoiConfirmation'
import ModalEoiEditResult from '../modals/ModalEoiEditResult'
import Button from '../components/Button'
import { v4 as uuidv4 } from 'uuid'

const showAllItems = { id: '-1', name: 'Show all', value: undefined }
const showAllScores = { id: '-1', name: 'All scores', value: undefined }
const allAvailableDates = { id: '999', name: 'Available all dates', value: 'all' }

const defaultCognifyScore: DropDownItem = {
  id: '0',
  name: 'Cognify scores',
  value: undefined,
  isLabel: true
}

const defaultAvailableDate: DropDownItem = {
  id: '0',
  name: 'Availability',
  value: undefined,
  isLabel: true
}

const defaultLocation: DropDownItem = {
  id: '0',
  name: 'Location',
  value: undefined,
  isLabel: true
}

const defaultBusinessUnit: DropDownItem = {
  id: '0',
  name: 'Business unit',
  value: undefined,
  isLabel: true
}

const dropDownItemHigherThan40 = { id: '1', name: '40 or higher', value: '>=40' }
const dropDownItemLessThan40 = { id: '2', name: 'Less than 40', value: '<40' }

const defualtCognifyScores: DropDownItem[] = [
  defaultCognifyScore,
  dropDownItemHigherThan40,
  dropDownItemLessThan40,
  showAllScores
]

interface Props {
  forIframe: boolean
}

export default function EoiProgramReport({ forIframe }: Props) {
  // console.log(`forIframe: ${forIframe}`)
  const { isSuperAdmin } = useAuth()
  const [program, setProgram] = useState<Program | undefined>(undefined)
  const [tableHeaders, setTableHeaders] = useState<any[]>([])
  const [learnerReports, setLearnerReports] = useState<LearnerReport[] | undefined>(undefined)
  const refAllLearnerReports = useRef<LearnerReport[] | undefined>([])
  const refAvailableDates = useRef<DropDownItem[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const refSearchTextInput = useRef<string>('')
  const { clientCode, programCode } = useParams()
  const slug = `${clientCode}/${programCode}`

  const [sortConfig, setSortConfig] = useState<{
    key: string
    direction: Direction
  } | null>(null)
  const [order, setOrder] = useState<{ [key: string]: Direction }>({})

  // TODO: pagination, default render rows for pagination
  const ROW_COUNT = 2000
  const [renderRowCount, setRenderRowCount] = useState<number>(ROW_COUNT)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [selectedLearnerReport, setSelectedLearnerReport] = useState<LearnerReport | undefined>(undefined)
  // dropdown items
  const [cognifyScores, setCognifyScores] = useState<DropDownItem[]>(defualtCognifyScores)
  const [availableDates, setAvailableDates] = useState<DropDownItem[]>([
    defaultAvailableDate,
    allAvailableDates,
    showAllItems
  ])
  const [locations, setLocations] = useState<DropDownItem[]>([defaultLocation, showAllItems])
  const [businessUnits, setBusinessUnits] = useState<DropDownItem[]>([defaultBusinessUnit, showAllItems])
  const [selectedCognifyScore, setSelectedCognifyScore] = useState<string | undefined>(undefined)
  const [selectedAvailableDate, setSelectedAvailableDate] = useState<string | undefined>(undefined)
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>(undefined)
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<string | undefined>(undefined)

  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [checkboxSelectAll, setCheckboxSelectAll] = useState(false)
  const [checkboxShowAll, setCheckboxShowAll] = useState(false)
  const [checkboxShowCompanyInformation, setCheckboxShowCompanyInformation] = useState(false)
  const [checkboxShowAvailability, setCheckboxShowAvailability] = useState(false)
  const [checkboxShowAttempts, setCheckboxShowAttempts] = useState(false)
  const [checkboxShowEmailConfirmations, setCheckboxShowEmailConfirmations] = useState(false)

  const refCompanyInformationKeys = useRef<string[]>([]) // by filter = company
  const refAvailabilityKeys = useRef<string[]>([]) // by filter = availability
  const refAttemptsKeys = useRef<string[]>([]) // by filter = attempts
  const refEmailConfirmationsKeys = useRef<string[]>([]) // by filter = email

  const [showModalInviteOrReject, setShowModalInviteOrReject] = useState<boolean>(false)
  const [inviteCohorts, setInviteCohorts] = useState<string[]>([])
  const [isInvite, setIsInvite] = useState<boolean>(true)
  const [isExporting, setIsExporting] = useState<boolean>(false)
  const refTableDiv = useRef<HTMLDivElement | null>(null)
  const [showFilters, setShowFilters] = useState<boolean>(true)

  // for confirmation dialog
  const refConfirmationPayload = useRef<{ id: string; body: any } | undefined>(undefined)
  const [confirmationData, setConfirmationData] = useState<{ applicationStatus: string }>({ applicationStatus: '' })
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
  const refPreSelectedDropdownId = useRef<string | undefined>(undefined)
  const [isUpdatingLearningReport, setIsUpdatingLearningReport] = useState<boolean>(false)
  const [isNewAdd, setIsNewAdd] = useState<boolean>(false)

  // for edit record modal
  const [showEditRecord, setShowEditRecord] = useState<boolean>(false)

  const hasFilter = useMemo(() => {
    return selectedCognifyScore || selectedAvailableDate || selectedLocation || selectedBusinessUnit
  }, [selectedAvailableDate, selectedBusinessUnit, selectedCognifyScore, selectedLocation])

  const updateDropDownItems = (reports: LearnerReport[]) => {
    // find unique data
    const uniqueDates = new Set<string>()
    const uniqueLocations = new Set<string>()
    const uniqueBusinessUnits = new Set<string>()

    reports.forEach(report => {
      if (report.dates) {
        report.dates.forEach(date => {
          uniqueDates.add(date)
        })
      }
      if (report.location) {
        uniqueLocations.add(report.location)
      }
      if (report.businessUnit) {
        uniqueBusinessUnits.add(report.businessUnit)
      }
    })

    const dates = Array.from(uniqueDates).map(date => {
      return { id: date, name: date, value: date }
    })

    const locations = Array.from(uniqueLocations).map(location => {
      return { id: location, name: location, value: location }
    })

    const businessUnits = Array.from(uniqueBusinessUnits).map(businessUnit => {
      return { id: businessUnit, name: businessUnit, value: businessUnit }
    })

    // sort by alphabetical order and move 'Show all' to the bottom
    dates.sort((a, b) => a.name.localeCompare(b.name))
    locations.sort((a, b) => a.name.localeCompare(b.name))
    businessUnits.sort((a, b) => a.name.localeCompare(b.name))

    setCognifyScores(defualtCognifyScores)

    // store to ref
    refAvailableDates.current = dates.map(date => {
      return {
        id: date.id,
        name: date.name,
        value: date.id
      }
    })

    setAvailableDates([defaultAvailableDate, ...refAvailableDates.current, allAvailableDates, showAllItems])
    setLocations([defaultLocation, ...locations, showAllItems])
    setBusinessUnits([defaultBusinessUnit, ...businessUnits, showAllItems])
  }

  const onSelectedCognifyScore = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedCognifyScore(item.value)
    } else if (item.id === '-1') {
      setSelectedCognifyScore(undefined)
    }
  }

  const onSelectedAvailableDate = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedAvailableDate(item.value)
    } else if (item.id === '-1') {
      setSelectedAvailableDate(undefined)
    }
  }

  const onSelectedLocation = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedLocation(item.value)
    } else if (item.id === '-1') {
      setSelectedLocation(undefined)
    }
  }

  const onSelectedBusinessUnit = (item: DropDownItem) => {
    if (item.value !== undefined && item.value !== null) {
      setSelectedBusinessUnit(item.value)
    } else if (item.id === '-1') {
      setSelectedBusinessUnit(undefined)
    }
  }

  const onInputChangedSearch = (value: string) => {
    refSearchTextInput.current = value
  }

  const onEnterSearch = async () => {
    // filter by search text (name or email)
    const searchText = refSearchTextInput.current.trim().toLowerCase()
    if (!searchText || !refAllLearnerReports.current) {
      // reset
      setLearnerReports(refAllLearnerReports.current)
      return
    }
    setIsSearching(true)
    const filteredItems = refAllLearnerReports.current.filter(item => {
      return (
        item.firstName?.toLowerCase().includes(searchText) ||
        item.lastName?.toLowerCase().includes(searchText) ||
        item.email?.toLowerCase().includes(searchText)
      )
    })

    setLearnerReports(filteredItems)
    setIsSearching(false)
  }

  const getTooptipStyle = (key: string): any => {
    tableHeaders.forEach(header => {
      if (header.key === key) {
        return order[key] === DirectionAsc ? tooltipStyleReverse : tooltipStyle
      }
    })
  }

  const getSortingText = (key: string): string => {
    let text = ''
    tableHeaders.forEach(header => {
      if (header.key === key) {
        text = order[key] === DirectionAsc ? 'Sort Z to A' : 'Sort A to Z'
      }
    })
    return text
  }

  const onClickSort = (key: string) => {
    let ascending = true
    if (sortConfig && sortConfig.key === key && sortConfig.direction === DirectionAsc) {
      ascending = false
    }
    setOrder({ [key]: ascending ? DirectionAsc : DirectionDesc })
    setSortConfig({ key, direction: ascending ? DirectionAsc : DirectionDesc })
  }

  const sortedLearnerReports = useMemo(() => {
    let sortableItems = learnerReports?.length ? [...learnerReports] : []
    // console.log(sortConfig)

    // filter by cognify score
    if (selectedCognifyScore) {
      if (selectedCognifyScore === dropDownItemHigherThan40.value) {
        sortableItems = sortableItems.filter(item => item.cognify && item.cognify >= 40)
      } else if (selectedCognifyScore === dropDownItemLessThan40.value) {
        sortableItems = sortableItems.filter(item => item.cognify && item.cognify < 40)
      }
    }

    // filter by available date
    if (selectedAvailableDate) {
      sortableItems = sortableItems.filter(item => {
        // for Mentem
        if (item.preferredCourseDate) {
          return item.preferredCourseDate === selectedAvailableDate
        } else {
          // check the key of object
          if (selectedAvailableDate === allAvailableDates.value) {
            // one of dates is 'Y', use of refAvailableDates
            return refAvailableDates.current.some(date => {
              return (item as any)[date.id] === 'Y'
            })
          } else {
            // console.log(selectedAvailableDate, item)
            const value = (item as any)[selectedAvailableDate]
            return value === 'Y'
          }
        }
      })
    }

    // filter by location
    if (selectedLocation) {
      sortableItems = sortableItems.filter(item => item.location === selectedLocation)
    }

    // filter by business unit
    if (selectedBusinessUnit) {
      sortableItems = sortableItems.filter(item => item.businessUnit === selectedBusinessUnit)
    }

    if (sortConfig !== null) {
      sortableItems.sort((a: any, b: any) => {
        // handle undefined values
        if (!a[sortConfig.key] && !b[sortConfig.key]) {
          return 0
        }
        if (!a[sortConfig.key]) {
          return sortConfig.direction === DirectionAsc ? -1 : 1
        }
        if (!b[sortConfig.key]) {
          return sortConfig.direction === DirectionAsc ? 1 : -1
        }

        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === DirectionAsc ? -1 : 1
        }

        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === DirectionAsc ? 1 : -1
        }
        return 0
      })

      // debug
      // sortableItems.forEach((item: any) => {
      //   console.log(item[sortConfig.key])
      // })
    }

    // always add 3 dummy rows for dropdown menu UX for the last row
    const dummyRows = 3
    for (let i = 0; i < dummyRows; i++) {
      sortableItems.push({} as any)
    }

    return sortableItems
  }, [selectedAvailableDate, selectedBusinessUnit, selectedCognifyScore, selectedLocation, sortConfig, learnerReports])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleScroll = useCallback(
    (e: Event) => {
      // check if scrolling to bottom
      const target = e.target as HTMLElement
      if (Math.ceil(target.scrollTop + target.clientHeight) >= Math.floor(target.scrollHeight)) {
        // console.log('scroll to bottom')
        // get the number of rows
        const rows = document.querySelectorAll('tbody tr')
        const startIndex = rows.length
        // render 10 more rows if available
        const endIndex = Math.min(startIndex + ROW_COUNT, sortedLearnerReports.length)
        if (endIndex <= startIndex) return
        // console.log('renderRows', startIndex, endIndex, renderRowCount)
        setRenderRowCount(endIndex)
      }
    },
    [sortedLearnerReports]
  )

  const getDateTimeString = (date: Date) => {
    if (!date) {
      return ''
    }
    // convert date to format: 6 Feb 2024 09:16
    return date.toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    })
  }

  // always hard code and frontend logic
  const getDisplayValue = useCallback((report: LearnerReport, header: any) => {
    const key = header.key
    switch (key) {
      case 'dates':
        return <div dangerouslySetInnerHTML={{ __html: getDatesHtml(report) }} />
      default:
        let value = (report as any)[key]

        // override values
        if (key === 'cognifyAttempts' || key === 'submissionAttempts') {
          return value === 1 ? value : <span className="text-[#ff0000]">{value}</span>
        } else if (key === 'cognify') {
          // if score is null, show empty, if score < 40, show red color
          if (value === null) {
            return ''
          } else if (value < 40) {
            return <span className="text-[#ff0000]">{value}</span>
          } else return value
        } else if (key === 'location') {
          // for RLA, if location is contain 'Other', pick otherLocation
          if (value && value.toLowerCase().includes('other')) {
            value = report.otherLocation ?? value
          }
        }
        if (value === 'N' && key !== 'firstName' && key !== 'lastName') {
          return <span className="text-[#ff0000]">{value}</span>
        }
        if (value === 'Requested') {
          return <span className="text-[#ff0000]">{value}</span>
        }
        // check is date format
        if (header.type === 'date') {
          if (value) {
            const date = new Date(value)
            return getDateTimeString(date)
          }
        } else if (key === 'lastName') {
          // TODO: discuss with Jenny
          // return <span className="text-start w-full px-[6px]">{value}</span>
        }

        return value
    }
  }, [])

  const updateLearnerReport = useCallback(
    async (id: string, body: any) => {
      try {
        // console.log('updateLearnerReport', id, body)
        if (showConfirmation) {
          setIsUpdatingLearningReport(true)
        }
        const updatedItem = await EoiAPI.updateLearnerReport(id, body)
        if (!updatedItem) {
          toastError('Failed to update the item')
          return
        }
        toastSuccess('Item has been updated')
        // find the index and update the item and re-render
        if (learnerReports) {
          const index = learnerReports.findIndex(report => report._id === updatedItem._id)
          if (index !== undefined && index !== -1) {
            const newReports = [...learnerReports]
            newReports[index] = updatedItem
            setLearnerReports(newReports)
          }
        }
      } catch (error) {
        console.error(error)
        toastError('Failed to update the item')
      } finally {
        if (showConfirmation) {
          setIsUpdatingLearningReport(false)
        }
      }
    },
    [learnerReports, showConfirmation]
  )

  const requireConfirmation = (item: DropDownItem, key: string): boolean => {
    return key === 'applicationStatus' && (item.value === 'Invited' || item.value === 'Rejected')
  }

  const onPreSelectedStatus = useCallback(async (result: LearnerReport, item: DropDownItem, key: string) => {
    // console.log(`onPreSelectedStatus: ${result._id}, ${item.value}, ${key}`)
    const body = { [key]: item.value }

    // if key is `applicationStatus` and the value is Invited or Rejected, show confirmation dialog
    if (requireConfirmation(item, key)) {
      refConfirmationPayload.current = { id: result._id!, body }
      setConfirmationData({ applicationStatus: item.value })
      setShowConfirmation(true)
    } else {
      // directly confirm the preselected item
      Emitter.emit(Events.OnConfirmedPreselectedItem, {
        id: refPreSelectedDropdownId.current
      })
      // reset
      refPreSelectedDropdownId.current = undefined
    }
  }, [])

  const onSelectedStatus = useCallback(
    async (result: LearnerReport, item: DropDownItem, key: string) => {
      if (!requireConfirmation(item, key)) {
        // update directly
        // console.log(`onSelectedStatus: ${result._id}, ${item.value}, ${key}`)
        const body = { [key]: item.value }
        await updateLearnerReport(result._id!, body)
      }
    },
    [updateLearnerReport]
  )

  const renderRow = useCallback(
    (item: any) => {
      const isDummy = !item._id
      const key = isDummy ? `dummy-${uuidv4()}` : item._id
      return (
        <tr key={key} className={isDummy ? 'dummy' : ''}>
          {tableHeaders.map((header, index: number) => {
            if (isDummy) {
              return (
                <td key={header.key}>
                  <div className="h-[52px]"></div>
                </td>
              )
            }
            return (
              <td className={alternateStyle('td', index % 2 === 0)} key={header.key}>
                {header.key === 'answers' && (
                  <div
                    className="flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      setSelectedLearnerReport(item)
                      setShowDetails(true)
                    }}>
                    <img src={noteSvg} alt="note" />
                  </div>
                )}
                {program?.adminHub.features?.editable && header.options && item.status && isSuperAdmin() ? (
                  <DropDownMenu
                    items={header.options.map((option: string, index: number) => {
                      return {
                        id: index.toString(),
                        name: option === '' ? '-' : option,
                        value: option,
                        default: item[header.key] === option
                      } as DropDownItem
                    })}
                    onSelected={selectedItem => {
                      onSelectedStatus(item, selectedItem, header.key)
                    }}
                    colorMapper={{
                      Rejected: '#ff0000',
                      Requested: '#ff0000',
                      N: '#ff0000'
                    }}
                    onPreSelected={
                      header.key === 'applicationStatus'
                        ? (selectedItem, dropDownId) => {
                            refPreSelectedDropdownId.current = dropDownId
                            onPreSelectedStatus(item, selectedItem, header.key)
                          }
                        : undefined
                    }
                    id={`dropdown-${item._id}-${header.key}`}
                    style={{ border: 'none', backgroundColor: 'transparent' }}
                  />
                ) : (
                  <div className="flex items-center justify-center">
                    {header.key === 'firstName' && (
                      <div className="flex flex-row items-center justify-between w-full px-[6px]">
                        {program?.adminHub.features?.editable && (
                          <>
                            <Checkbox
                              id={`checkbox-${item._id}`}
                              checked={checkedIds.includes(item._id)}
                              onChange={checked => {
                                if (checked) {
                                  setCheckedIds([...checkedIds, item._id])
                                  // check if all items are selected
                                  if (checkedIds.length + 1 === learnerReports?.length) {
                                    setCheckboxSelectAll(true)
                                  }
                                } else {
                                  setCheckedIds(checkedIds.filter(id => id !== item._id))
                                  // reset select all checkbox
                                  if (checkboxSelectAll) {
                                    setCheckboxSelectAll(false)
                                  }
                                }
                              }}
                              size={CheckboxSize.Medium}
                              theme={CheckboxTheme.Normal}
                            />
                            <div className="w-[6px]" />
                          </>
                        )}
                        <span className={`grow ${program?.adminHub.features?.editUser ? 'text-left' : ''}`}>
                          {item.firstName}
                        </span>
                        {program?.adminHub.features?.editUser && (
                          <div
                            className="cursor-pointer hover:opacity-[0.8]"
                            onClick={() => {
                              setIsNewAdd(false)
                              setSelectedLearnerReport(item)
                              setShowEditRecord(true)
                            }}>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g id="Eva / Edit / Line">
                                <path
                                  id="Style"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M12.9333 4.89324L11.1066 3.06657C10.6131 2.60301 9.8505 2.58294 9.33329 3.01991L3.33329 9.01991C3.1178 9.23722 2.98363 9.52204 2.95329 9.82657L2.66662 12.6066L2.66662 12.6066C2.64844 12.8043 2.71931 12.9998 2.85996 13.1399H2.85996C2.98577 13.2647 3.15609 13.3343 3.33329 13.3332H3.39329L6.17329 13.0799C6.47782 13.0496 6.76265 12.9154 6.97996 12.6999L12.98 6.69991C13.4648 6.18768 13.4439 5.37975 12.9333 4.89324V4.89324ZM6.05444 11.7465L4.05444 11.9332L4.23444 9.93322L8.00111 6.21322L9.80111 8.01322L6.05444 11.7465ZM10.6666 7.11991L8.87996 5.33324L10.18 3.99991L12 5.81991L10.6666 7.11991Z"
                                  fill="#BA61FF"
                                />
                              </g>
                            </svg>
                          </div>
                        )}
                      </div>
                    )}
                    {header.key !== 'firstName' && <>{getDisplayValue(item, header)}</>}
                  </div>
                )}
              </td>
            )
          })}
        </tr>
      )
    },
    [
      checkboxSelectAll,
      checkedIds,
      getDisplayValue,
      isSuperAdmin,
      learnerReports?.length,
      onPreSelectedStatus,
      onSelectedStatus,
      program?.adminHub.features,
      tableHeaders
    ]
  )

  const fetchLearnerReports = useCallback(async (programId: string) => {
    const reports = await EoiAPI.getLearnerReports(programId)

    // DEBUG
    if (process.env.REACT_APP_STAGE === 'local' && reports) {
      // reverse the order
      reports.reverse()
    }

    setLearnerReports(reports)
    refAllLearnerReports.current = reports
    updateDropDownItems(reports || [])
  }, [])

  const fetchProgram = useCallback(async () => {
    try {
      setIsLoading(true)
      const program = await EoiAPI.getProgram(slug)

      // DEBUG
      if (process.env.REACT_APP_STAGE === 'local' && program) {
        // reverse the order of adminHub.tableHeaders
        program.adminHub.tableHeaders.reverse()
      }

      setProgram(program)
      if (program && program.adminHub) {
        // set filter keys
        const companyInformationKeys = program.adminHub.tableHeaders
          .filter(header => header.filter === 'company')
          .map(header => header.key)
        refCompanyInformationKeys.current = companyInformationKeys

        const availabilityKeys = program.adminHub.tableHeaders
          .filter(header => header.filter === 'availability')
          .map(header => header.key)
        refAvailabilityKeys.current = availabilityKeys

        const attemptsKeys = program.adminHub.tableHeaders
          .filter(header => header.filter === 'attempts')
          .map(header => header.key)
        refAttemptsKeys.current = attemptsKeys

        const emailConfirmationsKeys = program.adminHub.tableHeaders
          .filter(header => header.filter === 'email')
          .map(header => header.key)
        refEmailConfirmationsKeys.current = emailConfirmationsKeys

        const filteredTableHeaders = program.adminHub.tableHeaders
          .filter(header => !refCompanyInformationKeys.current.includes(header.key))
          .filter(header => !refAvailabilityKeys.current.includes(header.key))
          .filter(header => !refAttemptsKeys.current.includes(header.key))
          .filter(header => !refEmailConfirmationsKeys.current.includes(header.key))
        setTableHeaders(filteredTableHeaders)

        // inviteCohorts are availability, filter out containing `recommend` case insensitive
        setInviteCohorts(availabilityKeys.filter(key => !key.toLowerCase().includes('recommend')))
      }
      if (program) {
        await fetchLearnerReports(program._id)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [fetchLearnerReports, slug])

  const getDatesHtml = (report: LearnerReport) => {
    if (report.dates && report.dates.length > 0) {
      return report.dates.join('<br/>')
    }
    return report.preferredCourseDate || ''
  }

  const onAddNew = async () => {
    if (!program) {
      toastError('Program is not found')
      return
    }
    setIsNewAdd(true)
    // dummy selected report
    setSelectedLearnerReport({
      program: program._id
    } as any)
    setShowEditRecord(true)
  }

  const onExportExcel = async () => {
    try {
      setIsExporting(true)
      if (program) {
        const response = await EoiAPI.exportReport(program._id)
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.href = url
        // file name is program name replace special characters
        const fileName = program.name.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
        link.setAttribute('download', `report-${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to export the report')
    } finally {
      setIsExporting(false)
    }
  }

  const onSendReminders = async () => {
    try {
      const ids = checkedIds
      if (ids.length === 0) {
        toastInfo('No candidates selected')
        return
      }
      const programId = program?._id
      if (programId) {
        const response = await EoiAPI.sendReminders(programId, ids)
        if (response) {
          toastSuccess('Reminders have been sent')
        } else {
          toastError('Failed to send reminders')
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const hasColumnCognify = useMemo(() => {
    return tableHeaders.some(header => header.key === 'cognify')
  }, [tableHeaders])

  const hasColumnLocation = useMemo(() => {
    return tableHeaders.some(header => header.key === 'location')
  }, [tableHeaders])

  const hasColumnBusinessUnit = useMemo(() => {
    return tableHeaders.some(header => header.key === 'businessUnit')
  }, [tableHeaders])

  const hasColumnAvailableDates = useMemo(() => {
    return availableDates.length > 0
  }, [availableDates])

  const onClickInviteToCohort = async () => {
    if (checkedIds.length === 0) {
      toastInfo('No candidates selected')
      return
    }
    setIsInvite(true)
    setShowModalInviteOrReject(true)
  }

  const onClickRejectFromCohort = async () => {
    if (checkedIds.length === 0) {
      toastInfo('No candidates selected')
      return
    }
    setIsInvite(false)
    setShowModalInviteOrReject(true)
  }

  const onShowAll = (checked: boolean) => {
    setCheckboxShowAll(true)
    if (checked) {
      setCheckboxShowCompanyInformation(true)
      setCheckboxShowAvailability(true)
      setCheckboxShowAttempts(true)
      setCheckboxShowEmailConfirmations(true)
      updateTableHeaders(true, true, true, true)
    } else {
      setCheckboxShowCompanyInformation(false)
      setCheckboxShowAvailability(false)
      setCheckboxShowAttempts(false)
      setCheckboxShowEmailConfirmations(false)
      updateTableHeaders(false, false, false, false)
    }
  }

  const onSelectAll = (checked: boolean) => {
    setCheckboxSelectAll(checked)
    if (checked) {
      setCheckedIds(learnerReports?.filter(report => report._id !== undefined).map(report => report._id!) || [])
    } else {
      setCheckedIds([])
    }
  }

  const updateTableHeaders = (
    showCompanyInformation: boolean,
    showAvailability: boolean,
    showAttempts: boolean,
    showEmailConfirmation: boolean
  ) => {
    const filteredTableHeaders = program?.adminHub.tableHeaders
      .filter((header: any) => {
        if (showCompanyInformation) {
          return true
        }
        return !refCompanyInformationKeys.current.includes(header.key)
      })
      .filter((header: any) => {
        if (showAvailability) {
          return true
        }
        return !refAvailabilityKeys.current.includes(header.key)
      })
      .filter((header: any) => {
        if (showAttempts) {
          return true
        }
        return !refAttemptsKeys.current.includes(header.key)
      })
      .filter((header: any) => {
        if (showEmailConfirmation) {
          return true
        }
        return !refEmailConfirmationsKeys.current.includes(header.key)
      })
    // console.log(showCompanyInformation, showAvailability, showAttempts, showEmailConfirmation)
    // console.log('filteredTableHeaders', filteredTableHeaders)
    setTableHeaders(filteredTableHeaders || [])
  }

  const onSucceedInviteOrRejectLearners = useCallback(() => {
    // update the learner reports
    fetchLearnerReports(program?._id || '')
  }, [fetchLearnerReports, program])

  const onUpdatedLearnerReport = useCallback(
    (data: { report: LearnerReport }) => {
      if (learnerReports && data.report) {
        const index = learnerReports.findIndex(report => report._id === data.report._id)
        if (index !== undefined && index !== -1) {
          const newReports = [...learnerReports]
          newReports[index] = data.report
          setLearnerReports(newReports)
        }
      }
    },
    [learnerReports]
  )

  const onAddedLearnerReport = useCallback(
    (data: { report: LearnerReport }) => {
      if (learnerReports && data.report) {
        setLearnerReports([...learnerReports, data.report])
        refAllLearnerReports.current = [...learnerReports, data.report]

        // scorll to the bottom of the table
        // setTimeout(() => {
        //   const table = refTableDiv.current
        //   if (table) {
        //     table.scrollTop = table.scrollHeight
        //   }
        // }, 1000)
      }
    },
    [learnerReports]
  )

  useEffect(() => {
    fetchProgram()
  }, [fetchProgram])

  useEffect(() => {
    Emitter.on(Events.OnSucceedInviteOrRejectLearners, onSucceedInviteOrRejectLearners)
    Emitter.on(Events.OnUpdatedLearnerReport, onUpdatedLearnerReport)
    Emitter.on(Events.OnAddedLearnerReport, onAddedLearnerReport)
    return () => {
      Emitter.off(Events.OnSucceedInviteOrRejectLearners, onSucceedInviteOrRejectLearners)
      Emitter.off(Events.OnUpdatedLearnerReport, onUpdatedLearnerReport)
      Emitter.off(Events.OnAddedLearnerReport, onAddedLearnerReport)
    }
  }, [onUpdatedLearnerReport, onSucceedInviteOrRejectLearners, onAddedLearnerReport])

  const renderContent = () => {
    return (
      <>
        <div
          id="report-container"
          className="flex grow w-full flex-col items-start gap-[12px] bg-white px-16 py-16 overflow-hidden">
          {isLoading && (
            <div className="flex w-full h-full items-center justify-center">
              <LoadingIndicator color={'#000'} />
            </div>
          )}
          {!isLoading && program && (
            <>
              <div className="flex flex-col gap-[12px] text-white w-full">
                <div className="flex flex-row items-center gap-[12px]">
                  <div className="eoi-subtitle">
                    <span>
                      {program.client} - {program.name} / Results -{' '}
                      <span className="text-primary">{learnerReports?.length || 0}</span>
                    </span>
                    {hasFilter && (
                      <span>
                        , filtered - <span className="text-primary">{sortedLearnerReports.length}</span>
                      </span>
                    )}
                  </div>
                  <div className="grow" />
                  <div>
                    <button
                      className="button-secondary"
                      onClick={() => {
                        setShowFilters(!showFilters)
                      }}>
                      {showFilters ? 'Hide Filters' : 'Show Filters'}
                    </button>
                  </div>
                  <div>
                    <Button
                      label={'Export Excel'}
                      disabled={isExporting}
                      isLoading={isExporting}
                      onClick={onExportExcel}
                      className="button-primary w-[140px]"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {!isLoading && learnerReports && (
            <>
              {showFilters && (
                <div className="flex flex-row items-center w-full gap-2">
                  <div className="w-full min-w-[300px]">
                    <SearchInput
                      onInputChanged={onInputChangedSearch}
                      onEnter={onEnterSearch}
                      isSearching={isSearching}
                      placeholder="Name or email"
                    />
                  </div>
                  {hasColumnCognify && (
                    <DropDownMenu
                      items={cognifyScores}
                      onSelected={onSelectedCognifyScore}
                      style={{ zIndex: 30, fontSize: '14px' }}
                    />
                  )}
                  {hasColumnAvailableDates && (
                    <DropDownMenu
                      items={availableDates}
                      onSelected={onSelectedAvailableDate}
                      style={{ zIndex: 30, fontSize: '14px' }}
                    />
                  )}
                  {hasColumnLocation && (
                    <DropDownMenu
                      items={locations}
                      onSelected={onSelectedLocation}
                      style={{ zIndex: 30, fontSize: '14px' }}
                    />
                  )}
                  {hasColumnBusinessUnit && (
                    <DropDownMenu
                      items={businessUnits}
                      onSelected={onSelectedBusinessUnit}
                      style={{ zIndex: 30, fontSize: '14px' }}
                    />
                  )}
                </div>
              )}

              {showFilters && (
                <div className="flex flex-col gap-[6px] w-full text-[12px]">
                  <div className="flex flex-row gap-[12px] text-left font-inter">
                    <Checkbox
                      id={'checkbox-show-company-information'}
                      checked={checkboxShowCompanyInformation}
                      onChange={checked => {
                        setCheckboxShowCompanyInformation(checked)
                        setCheckboxShowAll(
                          checked && checkboxShowAvailability && checkboxShowAttempts && checkboxShowEmailConfirmations
                        )
                        updateTableHeaders(
                          checked,
                          checkboxShowAvailability,
                          checkboxShowAttempts,
                          checkboxShowEmailConfirmations
                        )
                      }}
                      theme={CheckboxTheme.Normal}
                      label={<span>Show company information</span>}
                    />
                    <Checkbox
                      id={'checkbox-show-availability'}
                      checked={checkboxShowAvailability}
                      onChange={checked => {
                        setCheckboxShowAvailability(checked)
                        setCheckboxShowAll(
                          checked &&
                            checkboxShowAttempts &&
                            checkboxShowEmailConfirmations &&
                            checkboxShowCompanyInformation
                        )
                        updateTableHeaders(
                          checkboxShowCompanyInformation,
                          checked,
                          checkboxShowAttempts,
                          checkboxShowEmailConfirmations
                        )
                      }}
                      theme={CheckboxTheme.Normal}
                      label={<span>Show availability</span>}
                    />
                    <Checkbox
                      id={'checkbox-show-attempts'}
                      checked={checkboxShowAttempts}
                      onChange={checked => {
                        setCheckboxShowAttempts(checked)
                        setCheckboxShowAll(
                          checked &&
                            checkboxShowAvailability &&
                            checkboxShowEmailConfirmations &&
                            checkboxShowCompanyInformation
                        )
                        updateTableHeaders(
                          checkboxShowCompanyInformation,
                          checkboxShowAvailability,
                          checked,
                          checkboxShowEmailConfirmations
                        )
                      }}
                      theme={CheckboxTheme.Normal}
                      label={<span>Show attempts</span>}
                    />
                    <Checkbox
                      id={'checkbox-show-email-confirmations'}
                      checked={checkboxShowEmailConfirmations}
                      onChange={checked => {
                        setCheckboxShowEmailConfirmations(checked)
                        setCheckboxShowAll(
                          checked && checkboxShowAttempts && checkboxShowAvailability && checkboxShowCompanyInformation
                        )
                        updateTableHeaders(
                          checkboxShowCompanyInformation,
                          checkboxShowAvailability,
                          checkboxShowAttempts,
                          checked
                        )
                      }}
                      theme={CheckboxTheme.Normal}
                      label={<span>Show email confirmations</span>}
                    />
                    <div className="grow"></div>
                    <Checkbox
                      id={'checkbox-show-all'}
                      checked={checkboxShowAll}
                      onChange={checked => {
                        onShowAll(checked)
                      }}
                      theme={CheckboxTheme.Primary}
                      label={<span className="text-primary">Show all</span>}
                    />
                  </div>
                  {program?.adminHub.features?.editable && (
                    <div className="flex flex-row justify-between">
                      <div className="flex flex-row gap-[12px] items-center font-roboto">
                        <Checkbox
                          id={'checkbox-select-all'}
                          checked={checkboxSelectAll}
                          onChange={checked => {
                            onSelectAll(checked)
                          }}
                          theme={CheckboxTheme.Primary}
                          label={<span className="font-bold">Select all</span>}
                        />
                        <div className="flex flex-row">
                          <span className="text-[#05615E] cursor-pointer underline" onClick={onClickInviteToCohort}>
                            Invite to
                          </span>
                          <span>&nbsp;or&nbsp;</span>
                          <span className="text-[#FB0505] cursor-pointer underline" onClick={onClickRejectFromCohort}>
                            reject - from
                          </span>
                          <span>&nbsp;a cohort</span>
                        </div>
                      </div>
                      <div>
                        <button
                          className="button-secondary"
                          onClick={() => {
                            onSendReminders()
                          }}>
                          <div className="flex flex-row gap-2 items-center justify-center group">
                            <svg width="15" height="12" viewBox="0 0 15 12" xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.9999 0V4.61538H14.0769V1.90385L7.61533 5.1274L1.15379 1.90385V8.30769H7.61533V9.23077H0.230713V0H14.9999ZM7.61533 4.10337L13.9687 0.923077H1.26196L7.61533 4.10337ZM11.7692 5.53846C12.0865 5.53846 12.3846 5.59856 12.6634 5.71875C12.9423 5.83894 13.185 6.00481 13.3918 6.21635C13.5985 6.42788 13.7644 6.67308 13.8894 6.95192C14.0144 7.23077 14.0769 7.52885 14.0769 7.84615V9.23077H14.9999V10.1538H13.1538V10.6154C13.1538 10.8077 13.1177 10.988 13.0456 11.1562C12.9735 11.3245 12.8749 11.4712 12.7499 11.5962C12.6249 11.7212 12.4783 11.8197 12.31 11.8918C12.1418 11.9639 11.9615 12 11.7692 12C11.5769 12 11.3966 11.9639 11.2283 11.8918C11.06 11.8197 10.9134 11.7212 10.7884 11.5962C10.6634 11.4712 10.5648 11.3245 10.4927 11.1562C10.4206 10.988 10.3846 10.8077 10.3846 10.6154V10.1538H8.53841V9.23077H9.46148V7.84615C9.46148 7.52885 9.52158 7.23077 9.64177 6.95192C9.76196 6.67308 9.92542 6.43029 10.1322 6.22356C10.3389 6.01683 10.5841 5.85096 10.8677 5.72596C11.1514 5.60096 11.4519 5.53846 11.7692 5.53846ZM12.2307 10.1538H11.3076V10.6154C11.3076 10.7404 11.3533 10.8486 11.4447 10.9399C11.536 11.0312 11.6442 11.0769 11.7692 11.0769C11.8942 11.0769 12.0023 11.0312 12.0937 10.9399C12.185 10.8486 12.2307 10.7404 12.2307 10.6154V10.1538ZM13.1538 9.23077V7.84615C13.1538 7.65385 13.1177 7.47356 13.0456 7.30529C12.9735 7.13702 12.8749 6.99038 12.7499 6.86538C12.6249 6.74038 12.4783 6.64183 12.31 6.56971C12.1418 6.4976 11.9615 6.46154 11.7692 6.46154C11.5769 6.46154 11.3966 6.4976 11.2283 6.56971C11.06 6.64183 10.9134 6.74038 10.7884 6.86538C10.6634 6.99038 10.5648 7.13702 10.4927 7.30529C10.4206 7.47356 10.3846 7.65385 10.3846 7.84615V9.23077H13.1538Z" />
                            </svg>
                            <span className="text-[14px]">Send reminders</span>
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}

              <div
                ref={refTableDiv}
                className="flex flex-col gap-4 text-white max-h-[100%] w-full overflow-auto table-scrollbar table-scrollbar-grey">
                <table className="mentem-table mentem-table-highlight text-black">
                  <thead>
                    <tr>
                      {tableHeaders.map((header, index: number) => {
                        return (
                          <th
                            className={`${alternateStyle('th', index === 0)} h-[55px] min-w-[200px]`}
                            key={header.key}>
                            <div className="flex flex-row items-center gap-2 px-2">
                              <span className="grow">{header.value}</span>
                              {header.sorting !== false && (
                                <img
                                  className="cursor-pointer"
                                  src={index % 2 === 0 ? sortSvg : sort2Svg}
                                  alt="sort"
                                  onClick={() => onClickSort(header.key)}
                                  data-tooltip-id={`tooltip-${header.key}`}
                                />
                              )}
                            </div>
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {sortedLearnerReports.slice(0, Math.min(sortedLearnerReports.length, renderRowCount)).map(item => {
                      return renderRow(item)
                    })}
                  </tbody>
                </table>
              </div>
              {program?.adminHub.features?.editUser && (
                <Button
                  label={'Add candidate'}
                  onClick={onAddNew}
                  isLoading={false}
                  disabled={false}
                  className="button-primary w-[140px] whitespace-nowrap self-end"
                />
              )}
            </>
          )}
        </div>
        {showDetails && selectedLearnerReport && (
          <ModalEoiAnswerDetails
            report={selectedLearnerReport}
            onClose={() => {
              setShowDetails(false)
              setSelectedLearnerReport(undefined)
            }}
          />
        )}
        {showModalInviteOrReject && learnerReports && refAllLearnerReports.current && (
          <ModalEoiInviteOrReject
            reports={refAllLearnerReports.current}
            preCheckedIds={checkedIds}
            cohorts={inviteCohorts}
            isInvite={isInvite}
            onClose={() => {
              setShowModalInviteOrReject(false)
            }}
          />
        )}
        {showConfirmation && (
          <ModalEoiConfirmation
            data={confirmationData}
            isLoading={isUpdatingLearningReport}
            onClose={async confirm => {
              if (confirm) {
                if (refConfirmationPayload.current) {
                  await updateLearnerReport(refConfirmationPayload.current.id, refConfirmationPayload.current.body)
                  Emitter.emit(Events.OnConfirmedPreselectedItem, {
                    id: refPreSelectedDropdownId.current
                  })
                  // reset
                  refConfirmationPayload.current = undefined
                }
              }
              setShowConfirmation(false)
            }}
          />
        )}
        {showEditRecord && selectedLearnerReport && program && (
          <ModalEoiEditResult
            report={selectedLearnerReport}
            isNew={isNewAdd}
            program={program}
            onClose={() => {
              setShowEditRecord(false)
            }}
          />
        )}
      </>
    )
  }

  return (
    <>
      {forIframe ? (
        <div className="flex grow relative h-screen overflow-auto">{renderContent()}</div>
      ) : (
        <SidebarLayout>
          <div className="min-w-[1px]" />
          {renderContent()}
        </SidebarLayout>
      )}
      {tableHeaders.map(header => {
        return (
          <Tooltip
            id={`tooltip-${header.key}`}
            key={header.key}
            className="mentem-tooltip"
            style={getTooptipStyle(header.key)}
            place="top"
            noArrow={true}>
            {getSortingText(header.key)}
          </Tooltip>
        )
      })}
    </>
  )
}
