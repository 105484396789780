import React from 'react'
import ReactLoading from 'react-loading'

interface ButtonProps {
  label: string
  disabled: boolean
  isLoading: boolean
  onClick: () => void
  className?: string
}

const Button: React.FC<ButtonProps> = ({ label, disabled, isLoading, onClick, className }) => {
  return (
    <button className={className ? className : 'button-primary h-[32px]'} onClick={onClick} disabled={disabled}>
      {isLoading ? <ReactLoading type={'spinningBubbles'} color={'#eeeeee'} height={'16px'} width={'16px'} /> : label}
    </button>
  )
}

export default Button
