import { createContext, useContext, ReactNode, useEffect, useState, useRef } from 'react'
import { User as Auth0User, useAuth0 } from '@auth0/auth0-react'
import { jwtDecode } from 'jwt-decode'
import { User } from '../apis/entities/user.entity'
import { AdminAPI } from '../apis/AdminAPI'

export enum Role {
  // general
  None = '',
  All = 'All',
  // AdminHub
  AdminHubUser = 'AdminHubUser',
  // LXE
  LxAuthor = 'LxAuthor',
  LxDesigner = 'LxDesigner',
  LxAdmin = 'LxAdmin',
  LxSuperAdmin = 'LxSuperAdmin',
  // Insights
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  OrgAdmin = 'OrgAdmin'
}

export const AdminRoles = [Role.Admin, Role.SuperAdmin]

export enum Permission {
  ReadAssessments = 'read:ah_assessments',
  ReadEoi = 'read:ah_eoi',
  ReadClients = 'read:ah_clients',
  ReadLxeUsers = 'read:ah_lxe_users',
  ReadUsers = 'read:ah_users',
  UpdateClients = 'update:ah_clients',
  UpdateLxeUsers = 'update:ah_lxe_users',
  UpdateUsers = 'update:ah_users',
  CreateClients = 'create:ah_clients',
  CreateLxeUsers = 'create:ah_lxe_users',
  CreateUsers = 'create:ah_users'
}

export type Auth0UserDetails = {
  id: string
  email: string
  username: string
  firstName: string
}

type Props = {
  children: ReactNode
}

interface AuthValue {
  getRole: () => Role
  hasPermission: (permission: Permission) => boolean
  isLogged: boolean
  getIsLogged: () => boolean
  getAuth0UserDetails: () => Auth0UserDetails | undefined
  isSuperAdmin: () => boolean
  isAdministrator: () => boolean
  isReadOnlyUser: () => boolean
}

export const AuthContext = createContext<AuthValue | null>(null)

export function useAuth(): AuthValue {
  const state = useContext(AuthContext)
  if (!state) {
    throw new Error('useAuth must be used within AuthProvider')
  }
  return state
}

const AuthProvider = ({ children }: Props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const refAuth0User = useRef<Auth0User | undefined>(undefined)
  const refMentemUser = useRef<User | undefined>(undefined)
  const refPermissions = useRef<string[]>([])
  const [isLogged, setIsLogged] = useState<boolean>(false)
  const refIsLogged = useRef<boolean>(false)

  const getRoles = (): string[] => {
    if (isAuthenticated && user) {
      const auth0roles: string[] = user[`${process.env.REACT_APP_AUTH0_CUSTOM_CLAIMS_NAMESPACE}/roles`]
      const mentemRoles: string[] = refMentemUser.current?.roles.map(r => r.name) || []
      return auth0roles.concat(mentemRoles)
    }
    return []
  }

  const getRole = (): Role => {
    if (isAuthenticated && user) {
      const auth0roles: string[] = user[`${process.env.REACT_APP_AUTH0_CUSTOM_CLAIMS_NAMESPACE}/roles`]
      const mentemRoles: string[] = refMentemUser.current?.roles.map(r => r.name) || []
      const roles = auth0roles.concat(mentemRoles)
      if (roles.includes(Role.SuperAdmin)) {
        return Role.SuperAdmin
      } else if (roles.includes(Role.Admin)) {
        return Role.Admin
      } else if (roles.includes(Role.AdminHubUser)) {
        return Role.AdminHubUser
      } else if (roles.includes(Role.LxSuperAdmin)) {
        return Role.LxSuperAdmin
      } else if (roles.includes(Role.LxAdmin)) {
        return Role.LxAdmin
      } else if (roles.includes(Role.LxAuthor)) {
        return Role.LxAuthor
      } else if (roles.includes(Role.LxDesigner)) {
        return Role.LxDesigner
      } else if (roles.includes(Role.OrgAdmin)) {
        return Role.OrgAdmin
      }
    }
    return Role.None
  }

  const hasPermission = (permission: Permission): boolean => {
    return refPermissions.current?.includes(permission)
  }

  const getUserPermissions = (u: User): string[] => {
    if (u && u.roles && u.roles.length > 0) {
      const permissions = u.roles
        .map(r => r.permissions)
        .reduce((prev, curr) => {
          return [...prev, ...curr]
        }, [])
      return permissions
    }
    return []
  }

  const getAuth0UserDetails = (): Auth0UserDetails | undefined => {
    if (isAuthenticated && user) {
      const id = user?.sub || ''
      let username = ''
      if (user?.given_name && user?.family_name) {
        username = `${user?.given_name} ${user?.family_name}`
      } else {
        username = user?.nickname || user?.name || ''
      }
      const firstName = user?.given_name || ''
      const email = user?.email || ''
      return { id, email, username, firstName }
    }
    return undefined
  }

  const isAdministrator = (): boolean => {
    const roles = getRoles()
    return roles.some(r => AdminRoles.includes(r as Role))
  }

  const isReadOnlyUser = (): boolean => {
    const roles = getRoles()
    return roles.includes(Role.AdminHubUser)
  }

  useEffect(() => {
    if (isAuthenticated) {
      refAuth0User.current = user
      const getPermissionsFromToken = async () => {
        const token = await getAccessTokenSilently()
        const decodedToken: any = jwtDecode(token)
        console.log('decodedToken', decodedToken)
        const user = await AdminAPI.getUser(decodedToken.sub)
        // console.log(user)
        let permissions: string[] = []
        if (user && user.roles && user.roles.length > 0) {
          refMentemUser.current = user
          // mentem permissions
          permissions = getUserPermissions(user)
        }
        // plus auth0 permissions
        permissions = permissions.concat(decodedToken.permissions)
        // dedupe string array
        permissions = [...new Set(permissions)]
        refPermissions.current = permissions
        // console.log('permissions', permissions)
        // console.log(refAuth0User.current)
        // console.log(refMentemUser.current)
        refIsLogged.current = true
        setIsLogged(true)
      }
      getPermissionsFromToken()
    }
  }, [getAccessTokenSilently, isAuthenticated, user])

  const providerValue = {
    getRole,
    hasPermission,
    isLogged,
    getIsLogged: () => refIsLogged.current,
    getAuth0UserDetails,
    isSuperAdmin: () => getRole() === Role.SuperAdmin,
    isAdministrator,
    isReadOnlyUser
  }

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>
}

export default AuthProvider
