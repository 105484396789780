import { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { Organisation } from '../apis/entities/organisation.entity'
import { useAuth0 } from '@auth0/auth0-react'
import { AdminAPI } from '../apis/AdminAPI'

interface DataContextProps {
  clients: Organisation[] | undefined | null
}

const Context = createContext<DataContextProps | null>(null)

export const useDataContext = (): DataContextProps => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('data context must be inside a provider')
  }
  return context
}

type Props = {
  children: ReactNode
}
export const DataProvider = ({ children }: Props) => {
  const [clients, setClients] = useState<Organisation[] | undefined | null>(undefined)
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    const fetchData = async () => {
      const data = await AdminAPI.getClients()
      setClients(data)
    }
    if (isAuthenticated) {
      fetchData()
    }
  }, [isAuthenticated, setClients])

  const providerValue = {
    clients
  }

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
