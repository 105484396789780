import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import FullScreenLayout from '../components/FullscreenLayout'

export default function Debug() {
  const { logout, user, getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0()

  const fetchData = async () => {
    try {
      const accessToken = await getAccessTokenSilently()

      const response = await fetch(process.env.REACT_APP_API_URL + '/authed', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      const data = await response.json()
      console.log(data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <FullScreenLayout>
      <div className="flex grow p-4 items-center justify-center">
        <div className="">
          <h1>you are {isAuthenticated ? 'SIGNED IN' : 'NOT SIGNED IN'}</h1>

          {isAuthenticated && (
            <>
              <img src={user?.picture} alt={user?.name} />
              <h2>{user?.name}</h2>
              <p>{user?.email}</p>
              <h1>you are on the HOMEPAGE</h1>
              <button
                onClick={() =>
                  logout({
                    logoutParams: { returnTo: window.location.origin + '/debug' }
                  })
                }>
                Sign Out
              </button>
              <br />
              <button onClick={fetchData}>Fetch data</button>
            </>
          )}
          {!isAuthenticated && (
            <>
              <button
                onClick={() =>
                  loginWithRedirect({
                    appState: {
                      returnTo: '/debug'
                    }
                  })
                }>
                Sign In
              </button>
            </>
          )}

          <br />
          <Link to="/x/debug">NAKED Debug</Link>
          <br />
          <Link to="/x/">NAKED Home</Link>
          <br />
          <Link to="/x/settings">NAKED Settings</Link>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <Link to="/debug">PROTECTED Debug</Link>
          <br />
          <Link to="/">PROTECTED Home</Link>
          <br />
          <Link to="/settings">PROTECTED Settings</Link>
        </div>
      </div>
    </FullScreenLayout>
  )
}
