import { useCallback, useEffect, useRef, useState } from 'react'
import './DropDownMenu.css'
import { v4 as uuidv4 } from 'uuid'
import Emitter, { Events } from '../core/emitter'

/* exmaple usage
<DropDownMenu
  items={[
    {
      id: '0',
      name: 'Date',
      value: undefined,
      isLabel: true
    },
    { id: '1', name: 'Last 7 days', value: 7 },
    { id: '2', name: 'Last 30 days', value: 30 },
    { id: '3', name: 'Last 6 months', value: 183 },
    { id: '4', name: 'Show all', value: undefined }
  ]}
  onSelected={() => {}}
/>
*/

export type DropDownItem = {
  id: string
  name: string
  value?: any
  isLabel?: boolean
  isShowAllOptions?: boolean
  default?: boolean
}

type Props = {
  items: DropDownItem[]
  onSelected: (item: DropDownItem) => void
  value?: string
  colorMapper?: { [key: string]: string }
  onMouseOver?: () => void
  onClick?: () => void
  style?: {
    zIndex?: number
    fontSize?: string
    fontWeight?: string
    labelColor?: string
    justifyItems?: 'stretch' | 'center'
    border?: string
    backgroundColor?: string
  }
  onPreSelected?: (item: DropDownItem, dropDownId: string) => void
  id?: string // for receiving emitted events
  mode?: 'scroll' | 'popup'
}

export default function DropDownMenu({
  items,
  onSelected,
  value,
  colorMapper,
  onMouseOver,
  onClick,
  style,
  onPreSelected,
  id,
  mode
}: Props) {
  const refId = useRef<string>(id || uuidv4())
  const refMode = useRef<string>(mode || 'scroll')
  const [isShownDropDown, setIsShownDropDown] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<DropDownItem>()
  const refPreSelectedItem = useRef<DropDownItem>()
  const htmlRef = useRef<HTMLDivElement>(null)

  let customDropDownListItemClassName: string | null = null
  let customDropDownClassName: string | null = null

  const getItemName = () => {
    if (selectedItem) {
      // check if selected item exists in the items list, otherwise return the first item
      const tmpSelectedItem = items.find(item => item.id === selectedItem.id)
      if (!tmpSelectedItem) {
        setSelectedItem(items[0])
        return items[0].name
      }
      // Check if the selected item is marked with defaultValue: true
      if (selectedItem.isShowAllOptions) {
        // Find the item marked as a label and return its name
        const labelItem = items.find(item => item.isLabel)
        return labelItem ? labelItem.name : ''
      }
      // For other items, return their name as usual
      return selectedItem.name
    }
    return items.length > 0 ? items[0].name : ''
  }

  // const getItemLabel = (): boolean | undefined => {
  //   if (selectedItem) {
  //     return selectedItem.isLabel
  //   }
  //   return items.length > 0 ? items[0].isLabel : false
  // }

  const renderItemName = () => {
    const itemName = getItemName()
    return <span style={{ color: getTextColor(itemName) }}>{itemName}</span>
  }

  const onSelectItem = async (id: string) => {
    const item = items.find(item => id === item.id)
    if (item) {
      if (onPreSelected) {
        refPreSelectedItem.current = item
        onPreSelected(item, refId.current)
      } else {
        setSelectedItem(item)
        onSelected(item)
      }
    }
    setIsShownDropDown(false)
  }

  const getDropDownClassName = () => {
    return isShownDropDown
      ? 'dropdown-content z-[1] p-2 shadow bg-base-100 rounded max-h-[60vh] overflow-y-auto min-w-[max-content] w-full'
      : 'hidden'
  }

  const getDropDownListItemClassName = () => {
    // single line
    // return  'mentem-dropdown-button p-1 cursor-pointer bg-white hover:bg-primary hover:text-white whitespace-nowrap text-center',
    // multi line
    return 'mentem-dropdown-button p-1 cursor-pointer bg-white hover:bg-primary hover:text-white text-center w-full'
  }

  const hasItem = (): boolean => {
    // no need to show dropdown if there is only one non-label item and selected item is not a label
    if (!selectedItem) {
      if (items.length === 1 && items[0].isLabel) {
        return false
      }
    } else {
      if (items.length === 1) {
        return false
      } else if (items.length === 2 && items[0].isLabel && !selectedItem.isLabel) {
        return false
      }
    }
    return true
  }

  const onClickDropDown = () => {
    if (refMode.current === 'popup') {
      onClick && onClick()
    }
  }

  const onMouseOverDropDown = () => {
    if (refMode.current === 'popup') {
      return
    }
    setIsShownDropDown(hasItem())
    // trigger onMouseOver event if it is defined
    if (onMouseOver) {
      onMouseOver()
    }
  }

  const onConfirmedPreselectedItem = useCallback(
    (data: { id: string }) => {
      if (data.id === refId.current) {
        // console.log('onConfirmedPreselectedItem', data, refPreSelectedItem.current)
        if (refPreSelectedItem.current) {
          setSelectedItem(refPreSelectedItem.current)
          onSelected(refPreSelectedItem.current)
        }
      }
    },
    [onSelected]
  )

  // if the items are not empty and the first item is not a label, set the first item as the selected item
  useEffect(() => {
    if (value !== undefined) {
      const selectedItemBasedOnValue = items.find(item => item.value === value)
      if (selectedItemBasedOnValue) {
        setSelectedItem(selectedItemBasedOnValue)
        return
      }
    }

    // find default item
    const defaultItem = items.find(item => item.default)
    if (defaultItem) {
      setSelectedItem(defaultItem)
      return
    }

    if (items.length > 0 && !items[0].isLabel) {
      setSelectedItem(items[0])
    }
  }, [items, value])

  useEffect(() => {
    // update selected item if it is changed
    if (selectedItem) {
      const item = items.find(item => selectedItem.id === item.id)
      if (item && selectedItem.name !== item.name) {
        // console.log(`item ${item}`)
        setSelectedItem(item)
      }
    }
  }, [items, selectedItem])

  useEffect(() => {
    Emitter.on(Events.OnConfirmedPreselectedItem, onConfirmedPreselectedItem)
    return () => {
      Emitter.off(Events.OnConfirmedPreselectedItem, onConfirmedPreselectedItem)
    }
  }, [onConfirmedPreselectedItem])

  const getTextColor = (text: string) => {
    if (style?.labelColor) {
      return style.labelColor
    }

    if (colorMapper && colorMapper[text]) {
      return colorMapper[text]
    }
    return 'black'
  }

  const zIndex = style?.zIndex ? `z-[${style.zIndex}]` : 'z-1'
  const fontSize = style?.fontSize ? { fontSize: `${style.fontSize}` } : {}
  const fontWeight = style?.fontWeight ? { fontWeight: `${style.fontWeight}` } : {}
  const justifyItems = style?.justifyItems ? style.justifyItems : 'center'
  const cursorPointer = mode === 'popup' ? 'cursor-pointer' : ''
  const border = style?.border ? style.border : ''
  const backgroundColor = style?.backgroundColor ? style.backgroundColor : 'bg-white'

  return (
    <div
      className={`dropdown dropdown-hover w-full ${zIndex} ${cursorPointer}`}
      onClick={onClickDropDown}
      onMouseOver={onMouseOverDropDown}
      style={{ display: 'grid', justifyItems: justifyItems, border: border, backgroundColor: backgroundColor }}>
      <div ref={htmlRef} className={`flex items-center justify-center px-[6px]`}>
        {justifyItems === 'center' && <div className="grow" />}
        <label
          tabIndex={0}
          className={`mentem-dropdown-button mr-4 max-w-[400px] whitespace-normal ${cursorPointer}`}
          style={{
            ...fontSize,
            ...fontWeight
          }}>
          {renderItemName()}
        </label>
        <div className="grow" />
        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.292893 0.511643C0.653377 0.151159 1.22061 0.12343 1.6129 0.428455L1.70711 0.511643L7 5.80375L12.2929 0.511643C12.6534 0.151159 13.2206 0.12343 13.6129 0.428455L13.7071 0.511643C14.0676 0.872127 14.0953 1.43936 13.7903 1.83165L13.7071 1.92586L7.70711 7.92586C7.34662 8.28634 6.77939 8.31407 6.3871 8.00905L6.29289 7.92586L0.292893 1.92586C-0.0976311 1.53533 -0.0976311 0.902168 0.292893 0.511643Z"
            fill="#51597B"
          />
        </svg>
      </div>
      <div
        style={{
          display: 'grid',
          justifyItems: 'center',
          zIndex: `${style && style.zIndex ? style?.zIndex : 1}`
        }}>
        <ul tabIndex={0} className={customDropDownClassName || getDropDownClassName()}>
          {items
            .filter(item => !item.isLabel && item.id !== selectedItem?.id)
            .map(item => {
              return (
                <li
                  key={`dropdown-item-${uuidv4()}`}
                  className={customDropDownListItemClassName || getDropDownListItemClassName()}
                  onClick={() => onSelectItem(item.id)}
                  style={{
                    ...fontSize,
                    ...fontWeight
                  }}>
                  {item.name}
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}
