import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import { ReportAPI } from '../apis/ReportAPI'
import { Assessment } from '../apis/entities/report.entity'
import LoadingIndicator from '../components/LoadingIndicator'
import { useAuth } from '../context/AuthProvider'

export default function Assessments() {
  const [assessments, setAssessments] = useState<Assessment[] | null | undefined>(undefined)
  const { isLogged } = useAuth()

  const fetchData = useCallback(async () => {
    const data = await ReportAPI.getAssessments()
    console.log('data', data)
    if (data) {
      setAssessments(data)
    } else {
      setAssessments(null)
    }
  }, [])

  useEffect(() => {
    if (isLogged) {
      fetchData()
    }
  }, [fetchData, isLogged])

  const renderClients = () => {
    return assessments?.map((item, i) => {
      return (
        <Link to={`/assessment-report/${item.id}?v=${item.version}`} key={item.id}>
          <div className="rounded-lg bg-white px-4 py-5 shadow">
            <div className="pl-4 pr-4 text-xl font-semibold text-gray-900 h-10 flex items-center justify-center">
              {item.client}
            </div>
            <div className="pl-4 pr-4 text-2xl font-semibold text-gray-900 h-10 flex items-center justify-center">
              {item.program}
            </div>
          </div>
        </Link>
      )
    })
  }

  return (
    <SidebarLayout>
      <div className="flex grow flex-col items-center justify-center gap-8 bg-purple-950 p-4 relative">
        {!assessments && <LoadingIndicator />}
        {assessments && (
          <>
            <div className="page-title">Assessments</div>
            <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3">{renderClients()}</div>
          </>
        )}
      </div>
    </SidebarLayout>
  )
}
