import React from 'react'

interface Props {
  children: React.ReactNode
  elementType?: keyof JSX.IntrinsicElements
}

export default function FullScreenLayout({ children, elementType: ElementType = 'main' }: Props): JSX.Element {
  return <ElementType>{children}</ElementType>
}
