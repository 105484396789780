import React from 'react'
import './Switch.css'

interface Props {
  checked: boolean
  onChange: (checked: boolean) => void
  checkedText?: string
  unCheckedText?: string
  disabled?: boolean
}

export function Switch({ checked, onChange, checkedText, unCheckedText, disabled }: Props) {
  const [isOn, setIsOn] = React.useState(checked)

  const onClick = () => {
    if (disabled) return
    const checked = !isOn
    setIsOn(checked)
    onChange(checked)
  }

  const getStyle = () => {
    const style = isOn ? 'switch-on' : ''
    return `switch-container ${style}`
  }

  return (
    <div className="flex flex-row items-center justify-center gap-[8px]">
      <div className={getStyle()} onClick={onClick}>
        <div className="switch-thumb" />
      </div>
      <span className="switch-text whitespace-nowrap">{isOn ? checkedText || '' : unCheckedText || ''}</span>
    </div>
  )
}
