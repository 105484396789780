import { LearnerReport } from '../apis/entities/learnerReport.entity'
import closeSvg from '../images/close2.svg'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import Box from '@mui/material/Box'

interface Props {
  report: LearnerReport
  onClose: () => void
}

export default function ModalEoiAnswerDetails({ report, onClose }: Props): JSX.Element {
  console.log('ModalAnswerDetails', report)
  return (
    <div
      className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center"
      onClick={
        // click outside the widget to close
        e => {
          if (e.target === e.currentTarget) {
            // onClose()
          }
        }
      }>
      <div className="h-[90%] w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[12px]">
        <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
        <div className="flex flex-col items-center gap-1 pb-8 px-16 overflow-auto">
          {report.questions?.map((question, index) => (
            <div key={index} className="flex flex-col gap-1 w-full text-left font-roboto">
              <div className="text-[16px] font-[700] text-[#BD69FE]">Question {index + 1}</div>
              <div className="text-[16px] font-[400] text-[#121419]">{question.question}</div>
              <div className="text-[16px] font-[700]">Answer</div>
              <Box
                sx={{
                  fontSize: '16px',
                  textAlign: 'left',
                  color: '#121419'
                }}>
                <TextareaAutosize
                  minRows={1}
                  maxRows={2}
                  value={question.answer}
                  style={{
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    resize: 'none',
                    fontWeight: 600,
                    fontStyle: 'italic'
                  }}
                  disabled={true}
                />
              </Box>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
