interface Props {
  icon: string
}

function Svg({ icon }: Props) {
  const home = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
      />
    </svg>
  )

  const inbox = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
      />
    </svg>
  )

  const settings = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
      />
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>
  )

  const logout = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
      />
    </svg>
  )
  const dropdown = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="black"
      viewBox="0 0 1024 1024"
      stroke="currentColor"
      strokeWidth={20}>
      <path d="M680.1408 414.976c9.9328-8.704 24.2176-6.656 31.8976 4.608a27.8016 27.8016 0 0 1-4.096 35.84l-172.032 149.76a35.6352 35.6352 0 0 1-47.8208 0l-172.032-149.7088a27.8016 27.8016 0 0 1-4.096-35.9424c7.68-11.1616 22.016-13.2096 31.8976-4.608L512 561.3056l168.1408-146.2784z" />
    </svg>
  )
  const dropdownClose = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6 rotate-180"
      fill="black"
      viewBox="0 0 1024 1024"
      stroke="currentColor"
      strokeWidth={20}>
      <path d="M680.1408 414.976c9.9328-8.704 24.2176-6.656 31.8976 4.608a27.8016 27.8016 0 0 1-4.096 35.84l-172.032 149.76a35.6352 35.6352 0 0 1-47.8208 0l-172.032-149.7088a27.8016 27.8016 0 0 1-4.096-35.9424c7.68-11.1616 22.016-13.2096 31.8976-4.608L512 561.3056l168.1408-146.2784z" />
    </svg>
  )
  const letter = (
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.3333 0.333984H4.66661C2.46494 0.333984 0.678718 2.11276 0.666671 4.3116C0.666471 4.32428 0.66645 4.33695 0.66661 4.34961V17.6673C0.66661 19.8765 2.45747 21.6673 4.66661 21.6673H23.3333C25.5424 21.6673 27.3333 19.8765 27.3333 17.6673V4.35436C27.3335 4.33819 27.3335 4.32201 27.3332 4.30582C27.318 2.10964 25.533 0.333984 23.3333 0.333984ZM24.5172 3.71996C24.295 3.29258 23.8483 3.00065 23.3333 3.00065H4.66665C4.15169 3.00065 3.70492 3.29258 3.4828 3.71996L14 10.73L24.5172 3.71996ZM3.33331 6.82534V17.6673C3.33331 18.4037 3.93027 19.0007 4.66665 19.0007H23.3333C24.0697 19.0007 24.6666 18.4037 24.6666 17.6673V6.82534L14.7396 13.4434C14.3415 13.7088 13.8362 13.7383 13.4143 13.5319L13.2604 13.4434L3.33331 6.82534Z"
        fill="#DCDCDC"
      />
    </svg>
  )
  const lock = (
    <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6605 7.0435C17.5091 3.49576 14.585 0.666016 11 0.666016C7.31814 0.666016 4.33337 3.65078 4.33337 7.33268L4.33337 11.3327C2.12423 11.3327 0.333374 13.1235 0.333374 15.3327V23.3327C0.333374 25.5418 2.12423 27.3327 4.33337 27.3327H17.6667C19.8758 27.3327 21.6667 25.5418 21.6667 23.3327V15.3327C21.6667 13.1235 19.8758 11.3327 17.6667 11.3327V7.33268L17.6605 7.0435ZM15 11.3327V7.33268C15 5.12354 13.2092 3.33268 11 3.33268C8.8698 3.33268 7.12849 4.99791 7.00683 7.09765L7.00004 7.33268V11.3327H15ZM5.66671 13.9993H16.3334L17.6667 13.9993C18.4031 13.9993 19 14.5963 19 15.3327V23.3327C19 24.0691 18.4031 24.666 17.6667 24.666H4.33337C3.59699 24.666 3.00004 24.0691 3.00004 23.3327V15.3327C3.00004 14.5963 3.59699 13.9993 4.33337 13.9993L5.66671 13.9993ZM11 16.666C9.52728 16.666 8.33337 17.8599 8.33337 19.3327C8.33337 20.8054 9.52728 21.9993 11 21.9993C12.4728 21.9993 13.6667 20.8054 13.6667 19.3327C13.6667 17.8599 12.4728 16.666 11 16.666Z"
        fill="#DCDCDC"
      />
    </svg>
  )
  const close = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  )
  switch (icon) {
    case 'home':
      return home
    case 'inbox':
      return inbox
    case 'settings':
      return settings
    case 'logout':
      return logout
    case 'dropdown':
      return dropdown
    case 'dropdownClose':
      return dropdownClose
    case 'letter':
      return letter
    case 'lock':
      return lock
    case 'close':
      return close
    default:
      return null
  }
}

export default Svg
