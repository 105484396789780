export const checkDataRowsNull = (array: any[], columnIndex: number, fromRow: number): boolean => {
  for (let i = fromRow; i < array.length; i++) {
    // Start from fromRow to skip any header rows
    if (array[i][columnIndex] !== null) {
      return false // If any element at columnIndex is not null, return false
    }
  }
  return true
}
export const removeColumnWithIndex = (array: any[], columnIndex: number) => {
  for (let i = 0; i < array.length; i++) {
    array[i].splice(columnIndex, 1) // Remove the element (at index columnIndex)
  }
}
