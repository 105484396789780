import closeSvg from '../images/close2.svg'
import { DropDownItem } from './DropDownMenu'

interface Props {
  data: { key: string; items: DropDownItem[] }
  onClose: () => void
  onSelected: (key: string, item: DropDownItem) => void
}

export default function DropDownMenuList({ data, onClose, onSelected }: Props): JSX.Element {
  return (
    <div
      className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center"
      onClick={
        // click outside the widget to close
        e => {
          if (e.target === e.currentTarget) {
            onClose()
          }
        }
      }>
      <div className="h-auto max-h-[90%] min-w-[580px] flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]">
        <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
        <div className="py-[12px] px-[36px] overflow-auto w-full">
          <div className="flex flex-col items-center gap-[12px] w-full rounded-[4px] border shadow-xl">
            {data.items.map(item =>
              item.isLabel ? (
                <div key={item.id} className={`flex items-center justify-center px-[12px] py-[6px] w-full`}>
                  <label tabIndex={0} className="mentem-dropdown-popup-list-label whitespace-normal">
                    {item.name}
                  </label>
                  <div className="grow" />
                  <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.292893 0.511643C0.653377 0.151159 1.22061 0.12343 1.6129 0.428455L1.70711 0.511643L7 5.80375L12.2929 0.511643C12.6534 0.151159 13.2206 0.12343 13.6129 0.428455L13.7071 0.511643C14.0676 0.872127 14.0953 1.43936 13.7903 1.83165L13.7071 1.92586L7.70711 7.92586C7.34662 8.28634 6.77939 8.31407 6.3871 8.00905L6.29289 7.92586L0.292893 1.92586C-0.0976311 1.53533 -0.0976311 0.902168 0.292893 0.511643Z"
                      fill="#51597B"
                    />
                  </svg>
                </div>
              ) : (
                <div
                  key={item.id}
                  className="mentem-dropdown-button cursor-pointer bg-white hover:bg-primary hover:text-white text-start w-full px-[12px] py-[6px]"
                  onClick={() => {
                    onSelected(data.key, item)
                    onClose()
                  }}>
                  <span>{item.name}</span>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
