import { useCallback, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import LoadingIndicator from '../components/LoadingIndicator'
import { EoiAPI } from '../apis/EoiAPI'
import { Program } from '../apis/entities/program.entity'
import Path from '../routes/Path'
import { useAuth } from '../context/AuthProvider'
import { TextareaAutosize } from '@mui/material'
import { toastError, toastSuccess } from '../utils/toast'

export default function EOI() {
  const [programs, setPrograms] = useState<Program[] | null | undefined>(undefined)
  const { isLogged, isSuperAdmin } = useAuth()
  const [showEdit, setShowEdit] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState<Program | null>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const fetchProgramById = useCallback(async (programId: string) => {
    try {
      const result = await EoiAPI.getProgramById(programId)
      if (result) {
        // filter out the fields that are not allowed to be updated
        const filteredResult = {
          name: result.name,
          client: result.client,
          slug: result.slug,
          openDate: result.openDate,
          closeDate: result.closeDate,
          components: result.components,
          adminHub: result.adminHub,
          emailSettings: result.emailSettings
        } as Program
        setSelectedProgram(filteredResult)
        setShowEdit(true)
      } else {
        toastError('Failed to fetch program data')
      }
    } catch (error) {
      console.error(error)
      toastError('Failed to fetch program data')
    }
  }, [])

  const updateProgram = useCallback(async (slug: string, body: any) => {
    try {
      const result = await EoiAPI.updateProgram(slug, body)
      return result
    } catch (error) {
      console.error(error)
    }
  }, [])

  const fetchData = useCallback(async () => {
    try {
      const result = await EoiAPI.getPrograms()
      setPrograms(result)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const onClickEdit = async (program: Program) => {
    await fetchProgramById(program._id)
  }

  const onUpdateProgram = async (slug: string) => {
    if (!textareaRef.current) {
      return
    }
    const body = JSON.parse(textareaRef.current.value)
    const result = await updateProgram(slug, body)
    if (result) {
      toastSuccess('Program updated successfully')
    } else {
      toastError('Failed to update program')
    }
  }

  useEffect(() => {
    if (isLogged) {
      fetchData()
    }
  }, [fetchData, isLogged])

  const renderClients = () => {
    return programs?.map((item, i) => {
      const url = Path.eoiProgramReport.path.replace(':clientCode/:programCode', item.slug)
      return (
        <div className="flex flex-col gap-2" key={`program-${i}`}>
          <Link to={url} key={item._id}>
            <div className="rounded-lg bg-white px-4 py-5 shadow">
              <div className="pl-4 pr-4 text-xl font-semibold text-gray-900 h-10 flex items-center justify-center">
                {item.client}
              </div>
              <div className="pl-4 pr-4 text-2xl font-semibold text-gray-900 h-16 flex items-center justify-center">
                {item.name}
              </div>
            </div>
          </Link>
          <div className="flex justify-end p-1">
            {isSuperAdmin() && (
              <button className="text-white" onClick={() => onClickEdit(item)}>
                Edit
              </button>
            )}
          </div>
        </div>
      )
    })
  }

  return (
    <SidebarLayout>
      <div className="flex grow flex-col items-center justify-center gap-8 bg-purple-950 p-4 relative">
        {programs === undefined && <LoadingIndicator />}
        {programs && (
          <>
            <div className="page-title">Programs</div>
            <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3">{renderClients()}</div>
          </>
        )}
        {showEdit && (
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 w-3/4 h-3/4">
              {' '}
              <div className="flex justify-end text-2xl font-bold">
                <button onClick={() => setShowEdit(false)}>X</button>
              </div>
              <div className="flex flex-col text-2xl font-bold">
                <span>
                  {selectedProgram?.client} - {selectedProgram?.name}
                </span>
                <span className="text-[12px] font-normal">
                  Only allow to update openDate, closeDate, components, adminHub and emailSettings
                </span>
              </div>
              <br />
              <div className="w-full min-h-[70%] max-h-[70%] overflow-y-auto">
                <TextareaAutosize
                  ref={textareaRef}
                  className="w-full h-full border border-gray-300 text-[14px] focus:outline-none"
                  style={{ resize: 'none' }}
                  defaultValue={JSON.stringify(selectedProgram, null, 2)}
                />
              </div>
              <button
                className="bg-green-500 text-white mt-4 p-2 rounded-lg"
                onClick={() => onUpdateProgram(selectedProgram?.slug || '')}>
                Save
              </button>
            </div>
          </div>
        )}
      </div>
    </SidebarLayout>
  )
}
