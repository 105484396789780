import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LearnerReport } from '../apis/entities/learnerReport.entity'
import closeSvg from '../images/close2.svg'
import { EoiAPI } from '../apis/EoiAPI'
import { toastError, toastSuccess } from '../utils/toast'
import Button from '../components/Button'
import { Program } from '../apis/entities/program.entity'
import DropDownMenu, { DropDownItem } from '../components/DropDownMenu'
import Emitter, { Events } from '../core/emitter'
import DropDownMenuList from '../components/DropDownMenuList'
import { isEmail } from '../utils/stringUtils'
import Checkbox from '../components/Checkbox'

const defaultLocation: DropDownItem = {
  id: '-1',
  name: 'Location',
  value: undefined,
  isLabel: true
}

const defaultBusinessUnit: DropDownItem = {
  id: '-1',
  name: 'Business unit',
  value: undefined,
  isLabel: true
}

interface Props {
  report: LearnerReport
  isNew: boolean
  program: Program
  onClose: () => void
}

export default function ModalEoiEditResult({ report, isNew, program, onClose }: Props): JSX.Element {
  const refReport = useRef<LearnerReport | any>({ ...report })
  const [isUpdating, setIsUpdating] = useState(false)
  const [businessUnitOptions, setBusinessUnitOptions] = useState<DropDownItem[]>([])
  const [locationOptions, setLocationOptions] = useState<DropDownItem[]>([])
  const [cohortOptions, setCohortOptions] = useState<{ key: string; value: string }[]>([])
  const [showPopupList, setShowPopupList] = useState(false)
  const [popupListData, setPopupListData] = useState<{ key: string; items: DropDownItem[] }>({
    key: '',
    items: []
  })
  const [isReadyForSave, setIsReadyForSave] = useState(false)

  const currentColor = useMemo(() => (isReadyForSave ? '#000000' : '#787676'), [isReadyForSave])

  const validate = useCallback(() => {
    let isValid = true
    // all fields are required
    if (
      !refReport.current.firstName ||
      !refReport.current.lastName ||
      !refReport.current.email ||
      !refReport.current.managerEmail ||
      !refReport.current.managerFirstName ||
      !refReport.current.businessUnit ||
      !refReport.current.location
    ) {
      isValid = false
    }

    if (isValid) {
      // at least one cohort is required, check in refReport.current for cohort key
      cohortOptions.filter(item => refReport.current[item.key] === 'Y').length > 0
        ? (isValid = true)
        : (isValid = false)
    }

    setIsReadyForSave(isValid)
  }, [cohortOptions])

  const validatePayload = (body: any): boolean => {
    if (!body.firstName || !body.lastName || !body.email || !body.managerFirstName || !body.managerEmail) {
      toastError('Please fill all fields')
      return false
    }
    // business unit and location are required
    if (!body.businessUnit || !body.location) {
      toastError('Please select business unit and location')
      return false
    }

    // vlidate emails
    if (!isEmail(body.email)) {
      toastError('Invalid business email')
      return false
    }

    if (!isEmail(body.managerEmail)) {
      toastError('Invalid manager email')
      return false
    }

    // at least one cohort is required, check in refReport.current for cohort key
    // if (cohortOptions.filter(item => body[item.key] === 'Y').length === 0) {
    //   toastError('Please select at least one cohort')
    //   return false
    // }

    return true
  }

  const addLearnerReport = async (body: any) => {
    try {
      // validate body
      if (validatePayload(body) === false) {
        return
      }

      setIsUpdating(true)
      const addedReport = await EoiAPI.addLearnerReport(body)
      toastSuccess('Candidate report added')
      Emitter.emit(Events.OnAddedLearnerReport, { report: addedReport })
      onClose()
    } catch (error) {
      console.error(error)
      toastError('Failed to add candidate')
    } finally {
      setIsUpdating(false)
    }
  }

  const updateLearnerReport = async (body: any) => {
    try {
      // safe check
      if (report._id === undefined) {
        toastError('Failed to update candidate')
        return
      }

      // validate body
      if (validatePayload(body) === false) {
        return
      }

      setIsUpdating(true)
      const updatedReport = await EoiAPI.updateLearnerReport(report._id, body)
      toastSuccess('Candidate report updated')
      Emitter.emit(Events.OnUpdatedLearnerReport, { report: updatedReport })
      onClose()
    } catch (error) {
      console.error(error)
      toastError('Failed to update candidate')
    } finally {
      setIsUpdating(false)
    }
  }

  const onClickSaveOrConfirm = async () => {
    if (isNew) {
      await addLearnerReport(refReport.current)
    } else {
      await updateLearnerReport(refReport.current)
    }
  }

  useEffect(() => {
    if (!program.components?.sessionForm) return
    const businessUnits: any[] = program.components.sessionForm.businessUnits || []
    const locations: any[] = program.components.sessionForm.locations || []
    const dates: any[] = program.components.sessionForm.preferredCourseDates || []

    // init Dropdown options
    setBusinessUnitOptions([
      defaultBusinessUnit,
      ...businessUnits.map((item, index) => ({
        id: index.toString(),
        name: item,
        value: item,
        default: item === report.businessUnit
      }))
    ])
    setLocationOptions([
      defaultLocation,
      ...locations.map((item, index) => ({
        id: index.toString(),
        name: item,
        value: item,
        default: item === report.location
      }))
    ])

    // init cohort options
    const reportObject = { ...report } as any
    setCohortOptions(dates.map(item => ({ key: item, value: reportObject[item] })))
  }, [program, report])

  useEffect(() => {
    // validate on mount
    validate()
  }, [validate])

  return (
    <>
      <div className="w-full h-full absolute bg-[#35353BAA] z-[999] flex items-center justify-center">
        <div className="h-auto max-w-3/4 w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] px-[12px] pt-[12px] pb-[36px]">
          <img src={closeSvg} className="self-end cursor-pointer" alt="close" onClick={onClose} />
          <div className="flex flex-col items-center gap-[12px] px-16 overflow-auto w-full">
            <input
              type="text"
              id="firstName"
              placeholder="First name"
              className="input-text"
              defaultValue={report.firstName}
              onChange={e => {
                refReport.current.firstName = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <input
              type="text"
              id="lastName"
              placeholder="Last name"
              className="input-text"
              defaultValue={report.lastName}
              onChange={e => {
                refReport.current.lastName = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <input
              type="email"
              id="email"
              placeholder="Business email"
              className="input-text"
              defaultValue={report.email}
              onChange={e => {
                refReport.current.email = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <input
              type="email"
              id="managerEmail"
              placeholder="Direct Manager's email"
              className="input-text"
              defaultValue={report.managerEmail}
              onChange={e => {
                refReport.current.managerEmail = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <input
              type="text"
              id="managerFirstName"
              placeholder="Manager's first name"
              className="input-text"
              defaultValue={report.managerFirstName}
              onChange={e => {
                refReport.current.managerFirstName = e.target.value
                validate()
              }}
              style={{ color: currentColor }}
            />
            <DropDownMenu
              items={businessUnitOptions}
              onSelected={item => {
                refReport.current.businessUnit = item.value
                validate()
              }}
              style={{ justifyItems: 'stretch', fontSize: '15px', fontWeight: '600', labelColor: currentColor }}
              mode="popup"
              onClick={() => {
                setPopupListData({
                  key: 'businessUnit',
                  items: businessUnitOptions
                })
                setShowPopupList(true)
              }}
            />
            <DropDownMenu
              items={locationOptions}
              onSelected={item => {
                refReport.current.location = item.value
                validate()
              }}
              style={{ justifyItems: 'stretch', fontSize: '15px', fontWeight: '600', labelColor: currentColor }}
              mode="popup"
              onClick={() => {
                setPopupListData({
                  key: 'location',
                  items: locationOptions
                })
                setShowPopupList(true)
              }}
            />
            <span className="text-[14px] font-inter font-[400] self-start">
              Select the availability for this candidate
            </span>
            <div className="flex flex-wrap gap-2 w-full">
              {cohortOptions.map((item, index) => (
                <div key={index} className="flex items-center gap-[2px]">
                  <Checkbox
                    id={item.key}
                    checked={item.value === 'Y'}
                    onChange={checked => {
                      refReport.current[item.key] = checked ? 'Y' : 'N'
                      validate()
                    }}
                    label={item.key}
                    style={{ label: 'font-[700] font-inter text-[14px] ml-[2px]', color: currentColor }}
                  />
                </div>
              ))}
            </div>
            <div />
            <Button
              label={isNew ? 'Save' : 'Confirm'}
              disabled={isUpdating}
              isLoading={isUpdating}
              onClick={onClickSaveOrConfirm}
            />
          </div>
        </div>
      </div>
      {showPopupList && (
        <DropDownMenuList
          data={popupListData}
          onClose={() => setShowPopupList(false)}
          onSelected={(key, item) => {
            // console.log('selected item', key, item)
            if (key === 'businessUnit' && businessUnitOptions.includes(item)) {
              refReport.current.businessUnit = item.value
              // update selected item for dropdown by setting the selected item
              setBusinessUnitOptions(
                businessUnitOptions.map(option => {
                  if (option.id === item.id) {
                    return { ...option, default: true }
                  } else {
                    return { ...option, default: false }
                  }
                })
              )
            } else if (key === 'location' && locationOptions.includes(item)) {
              refReport.current.location = item.value
              // update selected item for dropdown by setting the selected item
              setLocationOptions(
                locationOptions.map(option => {
                  if (option.id === item.id) {
                    return { ...option, default: true }
                  } else {
                    return { ...option, default: false }
                  }
                })
              )
            }
            setShowPopupList(false)
          }}
        />
      )}
    </>
  )
}
