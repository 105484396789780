import SidebarLayout from '../components/SidebarLayout'
import ContactAdminSvg from '../images/contact-admin.svg'

interface Props {
  message: string
}

export const ErrorBoundary = ({ message }: Props) => {
  return (
    <SidebarLayout>
      <div className="flex grow flex-col items-center justify-center gap-8 bg-purple-950 p-4 relative">
        <div className="flex flex-col items-center gap-8 text-white">
          <img src={ContactAdminSvg} alt="Contact Admin" />
          <h1 className="font-bold text-[36px]">Oops...</h1>
          <p>{message}</p>
        </div>
      </div>
    </SidebarLayout>
  )
}
