import { Link } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import { useDataContext } from '../context/DataProvider'

export default function Clients() {
  const { clients } = useDataContext()

  const renderClients = () => {
    return clients?.map((item, i) => {
      return (
        <Link to={'/client/' + item.id} key={item.id}>
          <div className="rounded-lg bg-white px-4 py-5 shadow">
            <div className="pl-4 pr-4 text-2xl font-semibold text-gray-900 h-20 flex items-center justify-center">
              {item.name}
            </div>
          </div>
        </Link>
      )
    })
  }

  return (
    <SidebarLayout>
      <div className="flex grow flex-col items-center justify-center gap-8 bg-purple-950 p-4">
        <div className="page-title">Welcome to Admin Hub</div>
        <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3">{renderClients()}</div>
      </div>
    </SidebarLayout>
  )
}
