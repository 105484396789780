import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import { ReportAPI } from '../apis/ReportAPI'
import { CandidateReport, QuestionAnswered } from '../apis/entities/report.entity'
//flowchart
import 'reactflow/dist/base.css'

//chart
// import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
// import { AgChartThemePalette } from 'ag-charts-community/dist/cjs/chartOptions';
import { Chart } from 'react-google-charts'
import '../components/charts/styles.css'

import { PDFExport } from '@progress/kendo-react-pdf'
import React from 'react'
import { Assessment } from '../apis/entities/assessment.entity'
import ReactLoading from 'react-loading'
import { checkDataRowsNull, removeColumnWithIndex } from '../utils/arrayUtils'

export default function AssessmentIndividualReport() {
  // const { clients } = useDataContext()
  const [data, setData] = useState<CandidateReport | null | undefined>()
  // const [questions, setQuestions] = useState<Question[]>([])
  const [showAllOutcomes, setShowAllOutcomes] = useState(false)
  const [assessment, setAssessment] = useState<Assessment | null | undefined>(undefined)

  const location = useLocation()
  const lastPath = location.pathname.split('/').pop()
  const candidateId = lastPath || ''
  const version = new URLSearchParams(location.search).get('v') || ''

  //pdf
  const ref: any = React.createRef()

  const levels = data?.courses.map(course => course.level)
  const maxLevel = Math.max(...(levels ?? [0]))
  console.log('maxLevel', maxLevel)
  // console.log('maxLevel', maxLevel)

  const fetchData = async () => {
    const [data, assessment] = await Promise.all([
      ReportAPI.fetchIndividualReport(candidateId, version),
      ReportAPI.getAssessment(candidateId)
    ])
    // const data = await ReportAPI.fetchIndividualReport(candidateId)
    // const assessment = await ReportAPI.getAssessment(candidateId)
    console.log('data', data)
    if (data) {
      console.log('data', data)
      setData(data)
    }
    if (assessment?.questions) {
      console.log('assessment', assessment)
      setAssessment(assessment)
      // setQuestions(assessment.questions)
    }
    // setClients(data)
  }
  interface Outcome {
    name: string
    level: number
    result: boolean | null
  }
  const answeredQuestionsByLevel: QuestionAnswered[][] = levels?.map(level => []) ?? []
  data?.answeredQuestions.forEach(question => {
    if (!answeredQuestionsByLevel[question.level - 1]) {
      answeredQuestionsByLevel[question.level - 1] = []
    }
    answeredQuestionsByLevel[question.level - 1].push(question)
  })
  console.log('answeredQuestionsByLevel', answeredQuestionsByLevel)

  const outcomesByLevel: Outcome[][] = levels?.map(() => []) ?? []
  assessment?.questions.forEach(question => {
    if (!outcomesByLevel[question.level - 1]) {
      outcomesByLevel[question.level - 1] = []
    }

    // Find the outcomes list for the current level
    const outcomesForLevel = outcomesByLevel[question.level - 1]

    // Check if an outcome with the same name already exists in the outcomes list
    const existingOutcomeIndex = outcomesForLevel.findIndex(outcome => outcome.name === question.learningOutcome)

    if (existingOutcomeIndex === -1) {
      // If the outcome doesn't exist, add it to the outcomes list
      outcomesForLevel.push({
        name: question.learningOutcome,
        level: question.level,
        result:
          data?.answeredQuestions.find(answeredQuestion => answeredQuestion.questionId === question.id)?.isCorrect ??
          null
      })
    } else {
      // If the outcome exists, update its result if needed
      // TODO calculate % for multiple questions in the same learning outcomes
      const existingOutcome = outcomesForLevel[existingOutcomeIndex]
      const newResult =
        data?.answeredQuestions.find(answeredQuestion => answeredQuestion.questionId === question.id)?.isCorrect ?? null
      if (newResult !== null) {
        existingOutcome.result = newResult
      }
      // override old result if new result is false
      // if (newResult === false) {
      //   existingOutcome.result = newResult
      // }
    }
  })

  console.log('outcomesByLevel', outcomesByLevel)

  const correctAnswerRatesByLevel = answeredQuestionsByLevel
    .filter(levelQuestions => levelQuestions.length > 0)
    .map(levelQuestions => {
      return {
        level: levelQuestions[0].level,
        rate:
          Math.round(
            (100 * levelQuestions.filter(answeredQuestion => answeredQuestion.isCorrect).length) / levelQuestions.length
          ) / 100,
        passRate:
          (assessment?.classifiedLevels.length ?? 0) > 0
            ? assessment?.classifiedLevels.find(levelItem => levelItem.level === levelQuestions[0].level)
                ?.upThreshold ?? Infinity
            : assessment?.courses.find(course => course.level === levelQuestions[0].level)?.passScore ?? Infinity
      }
    })
  console.log('correctAnswerRatesByLevel', correctAnswerRatesByLevel)

  const correctAnswerDescriptions = correctAnswerRatesByLevel.map(level => {
    return `Level ${level.level} correct rate: ${100 * level.rate}%`
  })

  const rectifiedChartValue = (value: number): number => {
    const minCourseLevel =
      assessment?.courses.reduce(
        (min, course) => (course.level < min ? course.level : min),
        assessment?.courses[0].level
      ) ?? 1
    const maxCourseLevel =
      assessment?.courses.reduce(
        (max, course) => (course.level > max ? course.level : max),
        assessment?.courses[0].level
      ) ?? 1
    if (value < minCourseLevel) {
      return minCourseLevel - 0.5
    }
    if (value > maxCourseLevel) {
      return maxCourseLevel + 0.5
    }
    return value
  }

  const correctAnswerSummary = correctAnswerDescriptions.join(', ')
  console.log('correctAnswerSummary', correctAnswerSummary)

  const recommendationByCourseLevel: { level: number; pass: boolean }[] = []
  data?.courses.forEach(course => {
    let pass: boolean = false

    const assessedResult = correctAnswerRatesByLevel.find(level => level.level === course.level)
    if (assessedResult) {
      pass = assessedResult.rate >= assessedResult.passRate
    } else {
      pass = (data.finalLevel ?? 1) > course.level
    }

    recommendationByCourseLevel.push({ level: course.level, pass: pass })
  })
  console.log('recommendationByCourseLevel', recommendationByCourseLevel)

  let answerData: any[] = [['Question', 'Correct', 'Incorrect', 'Candidate level', 'Summary']]
  answerData.push(
    ...(data?.answeredQuestions
      ? data.answeredQuestions?.map(question => {
          return [
            {
              v: +question.questionNumber,
              f: `Question no.${question.questionNumber} is ${question.isCorrect ? 'correct' : 'incorrect'}`
            },

            {
              v: question.isCorrect ? rectifiedChartValue(+question.level) : null,
              f: `Overall level ${question.level} correct answer rate: ${Math.round(
                (100 *
                  answeredQuestionsByLevel[question.level - 1].filter(answeredQuestion => answeredQuestion.isCorrect)
                    .length) /
                  answeredQuestionsByLevel[question.level - 1].length
              )}%`
            },
            {
              v: question.isCorrect ? null : rectifiedChartValue(+question.level),
              f: `Overall level ${question.level} correct answer rate: ${Math.round(
                (100 *
                  answeredQuestionsByLevel[question.level - 1].filter(answeredQuestion => answeredQuestion.isCorrect)
                    .length) /
                  answeredQuestionsByLevel[question.level - 1].length
              )}%`
            },
            {
              v: rectifiedChartValue(+question.currentLevel),
              f: `${question.currentLevel}`
            },
            null
          ]
        })
      : [])
  )

  //final level
  answerData.push([
    { v: answerData.length, f: `Final level: ${data?.finalLevel}` },
    rectifiedChartValue(data?.finalLevel ?? 0),
    rectifiedChartValue(data?.finalLevel ?? 0),
    rectifiedChartValue(data?.finalLevel ?? 0),
    {
      v: rectifiedChartValue(data?.finalLevel ?? 0),
      f: data?.answeredQuestions ? correctAnswerSummary : ''
    }
  ])
  // if ((assessment?.classifiedLevels.length ?? 0) === 0) {
  //   answerData = answerData.map(([question, correct, incorrect]) => [question, correct, incorrect]);
  // }
  console.log('answerData', answerData)

  const ticksV = []
  for (let i = 1; i <= maxLevel; i++) {
    ticksV.push(i)
  }
  const ticksH = []
  for (let i = 0; i <= (data?.answeredQuestions.length ?? 0); i = i + 1) {
    ticksH.push(i)
  }
  const answerDataOptions = {
    series: {
      0: {
        type: 'scatter',
        color: 'blue'
      },
      1: {
        type: 'scatter',
        color: 'red'
      },
      2: {
        type: 'line',
        // pointSize: 4,
        // pointShape: 'circle', //'circle', 'triangle', 'square', 'diamond', 'star', or 'polygon'.
        color: 'purple'
      },
      3: {
        type: 'scatter',
        pointSize: 16,
        pointShape: 'star', //'circle', 'triangle', 'square', 'diamond', 'star', or 'polygon'.
        color: 'orange'
      }
    },
    // trendlines: {
    //   2: {
    //     type: "exponential",
    //     visibleInLegend: true,
    //   },
    // },
    hAxis: {
      title: 'Question number',
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, //, interval: 1}, // Color of gridlines
      minorGridlines: { color: '#FFF', interval: 3.5 }, // Color of minor gridlines
      baselineColor: '#FFF',
      minValue: 0,
      maxValue: (data?.answeredQuestions.length ?? 0) + 1,
      ticks: ticksH
    },
    vAxis: {
      title: 'Question level',
      minValue: 0,
      maxValue: maxLevel + 1,
      ticks: ticksV,
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF'
    },
    legend: {
      textStyle: { color: '#FFF' }, // Change color of the legend labels
      position: 'top',
      alignment: 'end'
    },
    // trendlines: {
    //   0: {
    //     color: '#888888',
    //     tooltip: 'Custom Trendline Tooltip'
    //   }
    // },
    height: 600,
    backgroundColor: 'transparent',
    chartArea: {
      top: 40,
      left: 100,
      bottom: 100,
      right: 100
    }
  }

  const answerPercentageData: any[] = [['Level', 'Correct answers', 'Incorrect answers']]
  answerPercentageData.push(
    ...(answeredQuestionsByLevel
      ? answeredQuestionsByLevel?.map((levelQuestions, index) => {
          return [
            `${data?.courses?.[index]?.title}`,
            levelQuestions.reduce((sum, question) => sum + (question.isCorrect ? 1 : 0), 0),
            levelQuestions.reduce((sum, question) => sum + (question.isCorrect ? 0 : 1), 0)
          ]
        })
      : [])
  )
  const answerPercentageOptions = {
    // orientation: 'vertical',
    isStacked: 'percent',
    bar: { groupWidth: '37%' },
    hAxis: {
      title: 'Area of competency',
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, //, interval: 1}, // Color of gridlines
      // minorGridlines: { color: '#FFF', interval: 3.5 }, // Color of minor gridlines
      baselineColor: '#FFF',
      minValue: 0
      // maxValue: (data?.answeredQuestions.length ?? 0) + 1,
      // ticks: ticksH
    },
    vAxis: {
      title: 'Answer correct rate(%)',
      // minValue: 0,
      // maxValue: maxLevel + 1,
      // ticks: ticksV,
      // ticks: [0, .3, .6, .9, 1],
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF'
    },
    legend: {
      textStyle: { color: '#FFF' }, // Change color of the legend labels
      position: 'right',
      alignment: 'start'
    },
    height: 600,
    backgroundColor: 'transparent', // Set the background color to transparent
    chartArea: {
      // backgroundColor: 'transparent', // Set chart area background to transparent
      top: 40,
      left: 100,
      bottom: 100,
      right: 200
    }
  }

  const timeData: any[] = [['Question', 'Correct', 'Incorrect']]
  timeData.push(
    ...(data?.answeredQuestions
      ? data.answeredQuestions?.map(question => {
          return [
            `${question.questionNumber}`,
            question.isCorrect ? Math.round(+question.duration * 100) / 100 : null,
            question.isCorrect ? null : Math.round(+question.duration * 100) / 100
          ]
        })
      : [])
  )
  //check null and remove columns
  if (checkDataRowsNull(timeData, 2, 1)) {
    removeColumnWithIndex(timeData, 2)
  }
  if (checkDataRowsNull(timeData, 2, 1)) {
    removeColumnWithIndex(timeData, 1)
  }

  console.log('timeData', timeData)

  const timeDataOptions = {
    // orientation: 'vertical',
    // bar: {groupWidth: "100%"},
    isStacked: true,
    hAxis: {
      title: 'Question number',
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, //, interval: 1}, // Color of gridlines
      // minorGridlines: { color: '#FFF', interval: 3.5 }, // Color of minor gridlines
      baselineColor: '#FFF',
      minValue: 0
      // maxValue: (data?.answeredQuestions.length ?? 0) + 1,
      // ticks: ticksH
    },
    vAxis: {
      title: 'Time taken (s)',
      // minValue: 0,
      // maxValue: maxLevel + 1,
      // ticks: ticksV,
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF'
    },
    legend: {
      textStyle: { color: '#FFF' }, // Change color of the legend labels
      position: 'top',
      alignment: 'end'
    },
    height: 600,
    backgroundColor: 'transparent', // Set the background color to transparent
    chartArea: {
      // backgroundColor: 'transparent', // Set chart area background to transparent
      top: 40,
      left: 100,
      bottom: 100,
      right: 100
    }
  }

  useEffect(() => {
    fetchData()
      .catch(console.error)
      .finally(() => {})
  }, [])

  return (
    <SidebarLayout>
      <div className="flex grow flex-col items-center gap-8">
        {data === undefined && (
          <ReactLoading
            className="fixed top-[50%]"
            type={'spinningBubbles'}
            color={'#eeeeee'}
            height={'64px'}
            width={'64px'}
          />
        )}
        <button
          className="text-purple-650 absolute right-4 top-8 bg-white px-4 py-2 rounded-lg font-bold"
          onClick={() => {
            if (ref.current) {
              ref.current.save()
            }
          }}>
          Export PDF
        </button>
        <PDFExport paperSize="auto" margin="0.5cm" ref={ref}>
          <div className="bg-purple-950 px-16 py-16">
            <div className="page-title text-4xl mb-16 relative">Assessment Report</div>
            {data && (
              <div className="w-[800px] space-y-20">
                <div className="text-left space-y-4">
                  <div className="text-white">Name: {data?.firstName + ' ' + data?.lastName}</div>
                  <div className="text-white">Program: {data.program}</div>
                  <div className="text-white">Date: {new Date(data?.submittedAt ?? '').toLocaleDateString()}</div>
                </div>

                {(assessment?.classifiedLevels.length ?? 0) > 0 && (
                  <div className="">
                    <div className="text-xl text-white mb-4">Assessed Levels</div>
                    <Chart chartType="ComboChart" data={answerData} options={answerDataOptions} />
                  </div>
                )}
                <div className="">
                  <div className="text-xl text-white mb-4">Answers correct rate</div>
                  <Chart chartType="ColumnChart" data={answerPercentageData} options={answerPercentageOptions} />
                </div>
                <div className="">
                  <div className="text-xl text-white mb-4">Answer Time</div>
                  <Chart chartType="ColumnChart" data={timeData} options={timeDataOptions} />
                </div>

                <div className="">
                  <div className="text-2xl text-white">Recommended Courses Overview</div>

                  <div className="text-white flex flex-row space-x-4 py-8 relative">
                    {data.courses.map((course, index) => (
                      <div className="relative min-h-full" key={'course-' + index}>
                        {/* tick */}
                        {/* {recommendationByCourseLevel.find(recommendation => recommendation.level === course.level)
                          ?.pass && <img src={Tick} className="absolute right-4 top-4 w-8 h-8 opacity-100" alt="" />} */}
                        <div
                          key={index}
                          // transparency
                          className={
                            'w-64 border border-white rounded-lg px-4 py-6 relative h-full ' +
                            (recommendationByCourseLevel.find(recommendation => recommendation.level === course.level)
                              ?.pass
                              ? ' opacity-50'
                              : '')
                          }>
                          <div className="text-xl font-bold">
                            {index + 1}. {course.title}
                          </div>
                          <div className="mt-4">{course.description}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="">
                  <div className="text-2xl text-white">Assessed Competency Areas</div>
                  {/* show all */}
                  {showAllOutcomes && (
                    <div className="text-white flex flex-col py-8">
                      {outcomesByLevel.map((outcomeGroup, index) => (
                        <div key={index} className={'space-y-4 border border-white rounded-lg px-4 py-6 '}>
                          <div key={index} className={'text-xl font-bold'}>
                            <p>Level {levels ? levels![index] : 0}</p>
                            <div className="space-x-8">
                              <span>
                                achieved:{' '}
                                {
                                  // (
                                  //   answeredQuestionsByLevel.find(
                                  //     questions => questions[0].level === outcomeGroup[0].level
                                  //   ) ?? []
                                  // ).filter(answeredQuestion => answeredQuestion.isCorrect).length
                                  outcomeGroup.filter(outcome => outcome.result === true).length
                                }
                              </span>
                              <span>
                                assessed:{' '}
                                {
                                  // (
                                  //   answeredQuestionsByLevel.find(
                                  //     questions => questions[0].level === outcomeGroup[0].level
                                  //   ) ?? []
                                  // ).length
                                  outcomeGroup.filter(outcome => outcome.result !== null).length
                                }
                              </span>
                              <span>total: {(outcomeGroup ?? []).length}</span>
                            </div>
                          </div>
                          {outcomeGroup.map((outcome, index) => (
                            <div
                              key={index}
                              className={`text-left ${outcome.result === false ? 'text-red-400' : ''} ${
                                outcome.result === null ? 'opacity-40' : ''
                              }`}>
                              <li>{outcome.name}</li>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                  {/* hide unassessed */}
                  {!showAllOutcomes && (
                    <div className="text-white flex flex-col py-8">
                      {outcomesByLevel.map(
                        (outcomeGroup, index) =>
                          //has assessed outcomes at the level
                          outcomeGroup.filter(outcome => outcome.result !== null).length > 0 && (
                            <div key={index} className={'space-y-4 border border-white rounded-lg px-4 py-6 grow'}>
                              <div key={index} className={'text-xl font-bold'}>
                                Level {levels ? levels![index] : 0}{' '}
                                <div>
                                  {outcomeGroup.length > 0
                                    ? 'achieved assessed outcomes: ' +
                                      Math.round(
                                        (100 * outcomeGroup.filter(outcome => outcome.result).length) /
                                          outcomeGroup.filter(outcome => outcome.result !== null).length
                                      ) +
                                      '%'
                                    : ''}
                                </div>
                              </div>
                              {outcomeGroup.map(
                                (outcome, index) =>
                                  outcome.result != null && (
                                    <div
                                      key={index}
                                      className={
                                        'text-left' + (outcome.result === true ? '' : ' text-red-300 opacity-50')
                                      }>
                                      <li>{outcome.name}</li>
                                    </div>
                                  )
                              )}
                            </div>
                          )
                      )}
                    </div>
                  )}
                  <button className="text-blue-500 noExport" onClick={() => setShowAllOutcomes(!showAllOutcomes)}>
                    {showAllOutcomes ? 'Show less' : 'Show all'}
                  </button>
                </div>

                <div className="">
                  <div className="text-2xl text-white">Learning Outcomes of the course(s) </div>

                  <div className="text-white flex flex-col py-8">
                    {/* space-x-4 */}
                    {outcomesByLevel.map(
                      (outcomeGroup, index) =>
                        !recommendationByCourseLevel.find(
                          recommendation => recommendation.level === outcomeGroup[0]?.level
                        )?.pass && (
                          <div key={index} className={'space-y-4 border border-white rounded-lg px-4 py-6 '}>
                            {/* w-64 */}
                            <div key={index} className={'text-xl font-bold'}>
                              Level {levels ? levels![index] : 0}
                            </div>
                            {outcomeGroup.map(
                              (outcome, index) =>
                                outcome.result !== true && (
                                  <div key={index} className={`text-left`}>
                                    <li>{outcome.name}</li>
                                  </div>
                                )
                            )}
                          </div>
                        )
                    )}
                  </div>
                </div>

                <div className="p-10 text-white">Report date: {new Date().toLocaleDateString()}</div>
              </div>
            )}

            <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3"></div>
          </div>
        </PDFExport>
      </div>
    </SidebarLayout>
  )
}
