import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { AdminRoles, Role, useAuth } from '../context/AuthProvider'
import { ErrorBoundary } from '../pages/ErrorBoundary'

interface ProtectedRouteProps {
  component: React.ComponentType<any>
  roles?: Role[]
  [key: string]: any // Index signature to accept any additional props
}

const ProtectedRoute = ({ component, roles, ...args }: ProtectedRouteProps) => {
  const { error, user, logout } = useAuth0()
  const { getRole, isLogged } = useAuth()

  if (error) {
    return <ErrorBoundary message={error.message} />
  }

  if (isLogged && user) {
    const metadata = user['app_metadata']
    if (metadata && metadata['demo'] === true) {
      console.log('demo user')
      return (
        <div className="center">
          <p>Oops... You are a demo user.</p>
          <br />
          <p>
            Please{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => {
                logout({
                  logoutParams: { returnTo: window.location.origin }
                })
              }}>
              log out
            </span>{' '}
            and login with a different account.
          </p>
        </div>
      )
    }

    // role validation
    // if no roles are provided, use AdminRoles
    if (!roles) {
      roles = AdminRoles
    }

    if (roles && !roles.includes(Role.All)) {
      const role = getRole()
      // check if the user has one of admim roles
      if (!AdminRoles.includes(role)) {
        // check if the user has the required role
        if (roles && !roles.includes(role)) {
          return (
            <ErrorBoundary
              message="You have not been given access to view data. Please contact an administrator from your
          organisation."
            />
          )
        }
      }
    }
  }
  const Component = withAuthenticationRequired(component, args)
  return <Component {...args} />
}

export default ProtectedRoute
