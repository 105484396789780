import { reportApiClient as apiClient } from './configs/AxiosClient'
import { Assessment as ReportAssesment, AssessmentReportData, CandidateReport } from './entities/report.entity'
import { Assessment } from './entities/assessment.entity'
export const ReportAPI = {
  getAssessment: async (candidateId: string): Promise<Assessment | undefined> => {
    let url = `/candidates/${candidateId}/assessment`
    const response: any = await apiClient.get(url)
    return response.data
  },
  getAssessmentById: async (assessmentId: string): Promise<Assessment | undefined> => {
    let url = `/assessments/${assessmentId}?cache=0`
    const response: any = await apiClient.get(url)
    return response.data
  },

  getAssessments: async (): Promise<ReportAssesment[] | undefined> => {
    const response: any = await apiClient.get(`/assessments?cache=1`)
    return response.data
  },
  fetchReport: async (assessmentId: string, version: string = '1'): Promise<AssessmentReportData | undefined> => {
    const url = version === '2' ? 'v2/reports' : '/reports'
    const response: any = await apiClient.post(url, {
      assessmentId: assessmentId
    })
    return response.data
  },
  fetchIndividualReport: async (candidateId: string, version: string = '1'): Promise<CandidateReport | undefined> => {
    const url = version === '2' ? 'v2/reports/candidate' : '/reports/candidate'
    const response: any = await apiClient.post(url, {
      candidateId: candidateId
    })
    return response.data
  }
}
