import ReactDOM from 'react-dom/client'
import './index.css'
import './table.css'
import App from './App'
import * as Sentry from '@sentry/react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

// init Sentry
const stage = process.env.REACT_APP_STAGE
if (stage === 'development' || stage === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 0.1,
    environment: stage
  })
}

// @ts-ignore
const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate()

  // @ts-ignore
  const onRedirectCallback = appState => {
    navigate((appState && appState.returnTo) || window.location.pathname)
  }

  return (
    // @ts-ignore
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectCallback
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: 'openid profile create:users update:users delete:users read:cohorts',
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      }}
      useRefreshTokens={true}>
      <App />
    </Auth0ProviderWithRedirectCallback>
  </BrowserRouter>
)
