import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingIndicator from '../components/LoadingIndicator'
import { EoiAPI } from '../apis/EoiAPI'
import { Program } from '../apis/entities/program.entity'

export default function EoiClient() {
  const [programs, setPrograms] = useState<Program[] | null | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const result = await EoiAPI.getMyPrograms()
      setPrograms(result)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const renderClients = () => {
    return programs?.map((item, i) => {
      const url = `/eoi/${item.slug}`
      console.log(url)
      return (
        <Link to={url} key={item._id}>
          <div className="rounded-lg bg-white px-4 py-5 shadow">
            <div className="pl-4 pr-4 text-xl font-semibold text-gray-900 h-10 flex items-center justify-center">
              {item.client}
            </div>
            <div className="pl-4 pr-4 text-2xl font-semibold text-gray-900 h-16 flex items-center justify-center">
              {item.name}
            </div>
          </div>
        </Link>
      )
    })
  }

  return (
    <div className="flex grow flex-col items-center justify-center gap-8 p-4 relative" style={{ minHeight: '100vh' }}>
      {isLoading && <LoadingIndicator color="#000" />}
      {!isLoading && (programs?.length ?? 0) > 0 && (
        <>
          <div className="page-title text-black">Programs</div>
          <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3">{renderClients()}</div>
        </>
      )}
      {!isLoading && (programs?.length ?? 0) === 0 && (
        <div style={{ color: '#ffffff' }}>
          Find out more about EOI reports by contacting us at{' '}
          <a href="mailto:hello@mentem.co" target="_blank" rel="noreferrer">
            hello@mentem.co
          </a>
        </div>
      )}
    </div>
  )
}
