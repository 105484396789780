import { adminApiClient as apiClient } from './configs/AxiosClient'
import { ClientDetails } from './entities/clientDetails.entity'
import { Organisation } from './entities/organisation.entity'
import { RoleDetails } from './entities/role.entity'
import { User, UserDetails } from './entities/user.entity'

export const AdminAPI = {
  getClients: async (): Promise<Organisation[] | undefined> => {
    try {
      const response: any = await apiClient.get(`/organisations/`)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  getSuperAdmins: async (): Promise<UserDetails[] | undefined> => {
    try {
      const response: any = await apiClient.get(`/users`, { params: { role: 'SuperAdmin' } })
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  getUsersForAdminHub: async (): Promise<UserDetails[] | undefined> => {
    try {
      const response: any = await apiClient.get(`/users/admin-hub`)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  getClient: async (orgId: string): Promise<ClientDetails | undefined> => {
    try {
      const response: any = await apiClient.get(`/organisation/${orgId}`)
      return response.data
    } catch (e) {
      console.error(e)
    }
  },
  inviteOrgUser: async (options: {
    email: string
    role: string
    firstName?: string
    lastName?: string
    orgId?: string // if null defaults to users own org id
  }) => {
    options.role =
      {
        COACH: 'Coach',
        LEARNINGOPS: 'LearningOps',
        ORGADMIN: 'OrgAdmin',
        ADMIN: 'Admin',
        SUPERADMIN: 'SuperAdmin'
      }[options.role.toUpperCase()] || 'Coach'

    const response: any = await apiClient.post(`/graphql`, {
      operationName: 'InviteUser',
      query: 'mutation InviteUser($args: InviteUserInput!) {\n  inviteUser(args: $args)\n}',
      variables: {
        args: {
          ...options
        }
      }
    })

    return response.data
  },
  inviteOrgUsers: async (
    users: {
      email: string
      role: string
      firstName?: string
      lastName?: string
      orgId?: string // if null defaults to users own org id
    }[]
  ) => {
    for (const usr of users) {
      usr.role =
        {
          COACH: 'Coach',
          LEARNINGOPS: 'LearningOps',
          ORGADMIN: 'OrgAdmin',
          ADMIN: 'Admin',
          SUPERADMIN: 'SuperAdmin'
        }[usr.role.toUpperCase()] || 'Coach'
    }

    const response: any = await apiClient.post(`/graphql`, {
      operationName: 'InviteUsers',
      query: `
          mutation InviteUsers($args: [InviteUserInput!]!) { 
              inviteUsers(args: $args) { 
                  success
                  message
                  userId
              } 
          }`,
      variables: {
        args: users
      }
    })

    return response.data
  },
  inviteSuperAdmin: async (options: { email: string; firstName?: string; lastName?: string }) => {
    const response: any = await apiClient.post(`/graphql`, {
      operationName: 'InviteUser',
      query: 'mutation InviteUser($args: InviteUserInput!) {\n  inviteUser(args: $args)\n}',
      variables: {
        args: {
          email: options.email,
          role: 'SuperAdmin',
          firstName: options.firstName,
          lastName: options.lastName,
          orgId: '3b3ddad8-5798-488f-866b-696608ec9219' // hardcoded to UNSW (Horizons)
        }
      }
    })
    return response.data
  },
  deleteUsers: async (ids: String[], auth0Ids: string[]): Promise<string[] | undefined> => {
    const response: any = await apiClient.post(`/users/delete`, {
      ids: ids,
      auth0Ids: auth0Ids
    })
    return response.data
  },
  inviteUser: async (options: { email: string; role: string; firstName?: string; lastName?: string }) => {
    const response: any = await apiClient.post(`/graphql`, {
      operationName: 'InviteUser',
      query: 'mutation InviteUser($args: InviteUserInput!) {\n  inviteUser(args: $args)\n}',
      variables: {
        args: {
          email: options.email,
          role: options.role,
          firstName: options.firstName,
          lastName: options.lastName
        }
      }
    })
    return response.data
  },
  getLXEUsers: async (): Promise<UserDetails[] | undefined> => {
    const response: any = await apiClient.get(`/users/lxe`)
    return response.data
  },
  getRoles: async (): Promise<RoleDetails[] | undefined> => {
    const response: any = await apiClient.get(`/roles`)
    return response.data.roles
  },
  assignRoleToUser: async (userId: string, roleId: string) => {
    const response: any = await apiClient.post(`/users/${userId}/roles`, {
      roleIds: [roleId]
    })
    return response.data.success
  },
  assignRoleByEmail: async (email: string, roleId: string) => {
    const response: any = await apiClient.post(`/users/roles`, {
      email: email,
      roleIds: [roleId]
    })
    return response.data.success
  },
  deleteRoleFromUser: async (userId: string, roleId: string) => {
    const response: any = await apiClient.delete(`/users/${userId}/roles`, {
      data: {
        roleIds: [roleId]
      }
    })
    return response.data.success
  },
  getUser: async (auth0Id: string): Promise<User | null> => {
    try {
      const response: any = await apiClient.get(`/users/auth0Id/${auth0Id}`)
      return response.data
    } catch (e) {
      console.error(e)
    }
    return null
  }
}
