import React from 'react'
import Sidebar from './Sidebar'

interface Props {
  children: React.ReactNode
  elementType?: keyof JSX.IntrinsicElements
}

export default function SidebarLayout({ children, elementType: ElementType = 'main' }: Props): JSX.Element {
  return (
    <div className="flex">
      <Sidebar />
      <ElementType className="flex grow relative h-screen overflow-auto bg-purple-950">{children}</ElementType>
    </div>
  )
}
