import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SidebarLayout from '../components/SidebarLayout'
import { ReportAPI } from '../apis/ReportAPI'
import { AssessmentReportData, Individual } from '../apis/entities/report.entity'

//chart
// import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
// import { AgChartThemePalette } from 'ag-charts-community/dist/cjs/chartOptions';
import { Chart, ReactGoogleChartEvent } from 'react-google-charts'
import '../components/charts/styles.css'

import { PDFExport } from '@progress/kendo-react-pdf'
import React from 'react'
import ReactLoading from 'react-loading'
import { Assessment, Assessment2 } from '../apis/entities/assessment.entity'

export default function AssessmentReport() {
  // const { clients } = useDataContext()
  const navigate = useNavigate()
  const [assessment, setAssessment] = useState<Assessment | Assessment2 | null | undefined>(undefined)

  const [data, setData] = useState<AssessmentReportData | null | undefined>(undefined)

  const location = useLocation()
  const lastPath = location.pathname.split('/').pop()
  const assessmentId = lastPath || ''
  const version = new URLSearchParams(location.search).get('v') || ''

  const individualsByLevel: Record<number, Individual[]> = {}

  //pdf
  const ref: any = React.createRef()

  let maxLevel = -1
  data?.individuals.forEach(individual => {
    if (!individualsByLevel[individual.finalLevel]) {
      individualsByLevel[individual.finalLevel] = []
    }
    if (individual.finalLevel > maxLevel) {
      maxLevel = individual.finalLevel
    }
    individualsByLevel[individual.finalLevel].push(individual)
  })
  console.log('individualsByLevel', individualsByLevel)

  const getDateTimeString = (date: Date): String => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')
    // const seconds = String(date.getSeconds()).padStart(2, '0');
    const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}`
    return dateTimeString
  }

  const fetchData = async () => {
    const [data, assessment] = await Promise.all([
      await ReportAPI.fetchReport(assessmentId, version),
      ReportAPI.getAssessmentById(assessmentId)
    ])
    // const data = await ReportAPI.fetchIndividualReport(candidateId)
    // const assessment = await ReportAPI.getAssessment(candidateId)
    console.log('data', data)
    if (data) {
      setData(data)
    } else {
      setData(null)
    }
    if (assessment?.questions) {
      console.log('assessment', assessment)
      setAssessment(assessment)
      // setQuestions(assessment.questions)
    } else {
      setAssessment(null)
    }
    // setClients(data)
  }

  const chartData1 = [
    ['Task', 'Hours per Day'],
    ['Work', 11],
    ['Eat', 11],
    ['Commute', 22],
    ['Watch TV', 33],
    ['Sleep', 44]
  ]
  const options1 = {
    pieSliceText: 'value',
    legend: {
      position: 'labeled',
      textStyle: { color: '#ffffff', fontSize: 16 },
      color: '#ffffff'
      // alignment: 'start',
    },
    height: 600,
    backgroundColor: 'transparent',
    is3D: true
  }
  console.log('data', data)
  let didTest: number = data ? +data?.submitted_count : 0
  let didNotTest: number = data ? +data?.invited_count - data?.submitted_count : 0
  const chartData2 = data
    ? [
        ['Did or din not finish', 'number'],
        [`In ${+data?.invited_count} invitations,`, { v: didTest, f: `Finished test: ${didTest}` }],
        [`In ${+data?.invited_count} invitations,`, { v: didNotTest, f: `Didn't take test: ${didNotTest}` }]
      ]
    : []
  const options2 = {
    pieSliceText: 'value',
    legend: 'none',

    chartArea: {
      height: '100%',
      width: '100%',
      top: 12,
      left: 12,
      right: 12,
      bottom: 12
    },
    height: 600,
    backgroundColor: 'transparent',
    is3D: true
  }

  const chartLevelData: [any] = [['Level', 'Number of individuals']]

  for (const level in individualsByLevel) {
    if (+level > 0 && individualsByLevel.hasOwnProperty(level)) {
      chartLevelData.push([
        `Recommended course level`,
        { v: individualsByLevel[level].length, f: `level ${level}: ${individualsByLevel[level].length} learners` }
      ])
    }
  }

  const chartLevelOptions = {
    pieSliceText: 'value',
    legend: 'none',

    chartArea: {
      height: '100%',
      width: '100%',
      top: 12,
      left: 12,
      right: 12,
      bottom: 12
    },
    height: 600,
    backgroundColor: 'transparent',
    is3D: true
  }

  let participantData: any[] = [['Name', 'Email', 'Recommended level', 'Submitted at', 'levels']]
  const sortedIndividuals = data?.individuals.filter(individual => individual.finalLevel !== null)
  // .sort((a, b) => a.finalLevel - b.finalLevel)

  participantData.push(
    ...(sortedIndividuals
      ? sortedIndividuals?.map(individual => {
          return [
            `${individual.firstName} ${individual.lastName}`,
            individual.email,
            individual.finalLevel,
            getDateTimeString(new Date(individual.submittedAt)),
            // `${individual.result?.focusAreaResults
            //   .map(focusAreaResult => `${focusAreaResult.level}`.padStart(4))
            //   .join(' | ')}`
            `${String(individual.level)} ( ${individual.focusAreaLevels?.map(level => String(level)).join(' | ')})`
          ]
        })
      : [])
  )
  if (((assessment as Assessment)?.classifiedLevels.length ?? 0) === 0) {
    participantData = participantData.map(([name, email, _level, submittedAt, level]) => [
      name,
      email,
      submittedAt,
      level
    ])
  }

  const allAnsweredQuestionLevels =
    data?.individuals
      .map(individual => individual.levels)
      .flat()
      .filter(level => level != null) ?? []
  const allAnsweredQuestionCorrectness =
    data?.individuals
      .map(individual => individual.isCorrect)
      .flat()
      .filter(isCorrect => isCorrect != null) ?? []
  const allAnsweredQuestionLevelAndCorrectness = allAnsweredQuestionLevels.map((level, index) => [
    level,
    allAnsweredQuestionCorrectness[index]
  ])
  const allAnsweredQuestionLevelAndCorrectnessGroupByLevel: Record<number, boolean[]> =
    allAnsweredQuestionLevelAndCorrectness.reduce((acc, [level, isCorrect]) => {
      if (!acc[level as number]) {
        acc[level as number] = []
      }
      acc[level as number].push(isCorrect as boolean)
      return acc
    }, {} as Record<number, boolean[]>)

  const answerPercentageData: any[] = [['Level', 'Correct answers', 'Incorrect answers']]
  for (const key in allAnsweredQuestionLevelAndCorrectnessGroupByLevel) {
    if (allAnsweredQuestionLevelAndCorrectnessGroupByLevel.hasOwnProperty(key)) {
      const array = allAnsweredQuestionLevelAndCorrectnessGroupByLevel[key]
      const numberOfTrues = array.filter(value => value === true).length
      const numberOfFalses = array.length - numberOfTrues
      answerPercentageData.push([
        { v: key, f: assessment?.courses.find(course => `${course.level}` === key)?.title },
        numberOfTrues,
        numberOfFalses
      ])
    }
  }

  const answerPercentageData2: any[] = [['Level', 'Correct answers', 'Incorrect answers']]
  const framework = (assessment as Assessment2)?.framework
  const frameworkLevels =
    framework?.levels.map(
      (level, index) => level.level // level.title
    ) ?? []
  const correctRatesData =
    data?.correctRates?.map(correctRate => [
      { v: correctRate.level, f: `level ${correctRate.level}`},
      +correctRate.correctAnswers,
      +correctRate.totalAnswers - correctRate.correctAnswers,
    ]) ?? []
  answerPercentageData2.push(...correctRatesData)

  // const allTestTime =
  //   data?.individuals
  //     .map(individual => (individual.levels?.length ?? 0) * +individual.avgQuestionTaken)
  //     .filter(time => time) ?? []
  // console.log('allTestTime', allTestTime)
  const timeData: any[][] = [['email', 'time']]
  data?.individuals
    .filter(individual => individual.avgQuestionTaken)
    .forEach(individual => {
      // console.log('individual', individual)
      timeData.push([individual.email, (individual.levels?.length ?? 0) * Math.round(+individual.avgQuestionTaken)])
    })
  console.log('timeData:', timeData)
  const timeDataOptions = {
    // isStacked: 'percent',
    // bar: { groupWidth: '37%' },
    hAxis: {
      title: 'Time Taken (s)',
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, //, interval: 1}, // Color of gridlines
      // minorGridlines: { color: '#FFF', interval: 3.5 }, // Color of minor gridlines
      baselineColor: '#FFF',
      minValue: 0
      // maxValue: (data?.answeredQuestions.length ?? 0) + 1,
      // ticks: ticksH
    },
    vAxis: {
      title: 'Number of participants',
      // minValue: 0,
      // maxValue: maxLevel + 1,
      // ticks: ticksV,
      // ticks: [0, .3, .6, .9, 1],
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF'
    },
    legend: {
      textStyle: { color: '#FFF' }, // Change color of the legend labels
      position: 'right',
      alignment: 'start'
    },
    height: 600,
    backgroundColor: 'transparent', // Set the background color to transparent
    chartArea: {
      // backgroundColor: 'transparent', // Set chart area background to transparent
      top: 40,
      left: 100,
      bottom: 100,
      right: 200
    }
  }

  const questionCountData: any[][] = [['email', 'candidate answered questions', 'dropout answered questions']]
  data?.individuals
    .filter(individual => individual.avgQuestionTaken)
    .forEach(individual => {
      questionCountData.push([`${individual.email}`, individual.levels?.length ?? 0, null])
    })
  data?.dropouts?.forEach(dropout => {
    questionCountData.push([`${dropout.id}`, null, dropout.answerCount])
  })
  console.log('questionCountData:', questionCountData)
  const maxLevelsLength = Math.max(...(data?.individuals?.map(individual => individual.levels?.length || 0) || [0]))
  // let questionLengthTicks = []
  // for (let i = 1; i <= maxLevelsLength; i = i + 1) {
  //   questionLengthTicks.push(i)
  // }
  const questionCountDataOptions = {
    // isStacked: 'percent',
    // bar: { groupWidth: '37%' },
    histogram: {
      bucketSize: 1, // Specify the bin width (range interval)
      minValue: 0, // Specify the minimum value for the range
      maxValue: maxLevelsLength // Specify the maximum value for the range
    },

    hAxis: {
      title: 'Number of questions answered',
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, //, interval: 1}, // Color of gridlines
      // minorGridlines: { color: '#FFF', interval: 3.5 }, // Color of minor gridlines
      baselineColor: '#FFF',
      minValue: 0
      // maxValue: (data?.answeredQuestions.length ?? 0) + 1,
      // ticks: questionLengthTicks,
      // interval: 1
    },
    vAxis: {
      title: 'Number of participants',
      // minValue: 0,
      // maxValue: maxLevel + 1,
      // ticks: ticksV,
      // ticks: [0, .3, .6, .9, 1],
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF'
      // format: '0'
    },
    legend: {
      textStyle: { color: '#FFF' }, // Change color of the legend labels
      position: 'right',
      alignment: 'start'
    },
    height: 600,
    backgroundColor: 'transparent', // Set the background color to transparent
    chartArea: {
      // backgroundColor: 'transparent', // Set chart area background to transparent
      top: 40,
      left: 100,
      bottom: 100,
      right: 200
    }
  }

  const answerPercentageOptions = {
    // orientation: 'vertical',
    isStacked: 'percent',
    bar: { groupWidth: '37%' },
    hAxis: {
      title: 'Area of competency',
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, //, interval: 1}, // Color of gridlines
      // minorGridlines: { color: '#FFF', interval: 3.5 }, // Color of minor gridlines
      baselineColor: '#FFF',
      minValue: 0
      // maxValue: (data?.answeredQuestions.length ?? 0) + 1,
      // ticks: ticksH
    },
    vAxis: {
      title: 'Answer correct rate(%)',
      // minValue: 0,
      // maxValue: maxLevel + 1,
      // ticks: ticksV,
      // ticks: [0, .3, .6, .9, 1],
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF'
    },
    legend: {
      textStyle: { color: '#FFF' }, // Change color of the legend labels
      position: 'right',
      alignment: 'start'
    },
    height: 600,
    backgroundColor: 'transparent', // Set the background color to transparent
    chartArea: {
      // backgroundColor: 'transparent', // Set chart area background to transparent
      top: 40,
      left: 100,
      bottom: 100,
      right: 200
    }
  }

  const answerPercentageOptions2 = {
    // orientation: 'vertical',
    isStacked: 'percent',
    bar: { groupWidth: '37%' },
    hAxis: {
      title: 'Levels',
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, //, interval: 1}, // Color of gridlines
      // minorGridlines: { color: '#FFF', interval: 3.5 }, // Color of minor gridlines
      baselineColor: '#FFF',
      minValue: 0
      // maxValue: (data?.answeredQuestions.length ?? 0) + 1,
      // ticks: ticksH
    },
    vAxis: {
      title: 'Answer correct rate(%)',
      // minValue: 0,
      // maxValue: maxLevel + 1,
      // ticks: ticksV,
      // ticks: [0, .3, .6, .9, 1],
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF'
    },
    legend: {
      textStyle: { color: '#FFF' }, // Change color of the legend labels
      position: 'right',
      alignment: 'start'
    },
    height: 600,
    backgroundColor: 'transparent', // Set the background color to transparent
    chartArea: {
      // backgroundColor: 'transparent', // Set chart area background to transparent
      top: 40,
      left: 100,
      bottom: 100,
      right: 200
    }
  }

  const inactiveData: [any] = [['Name', 'Email', 'Invited at']]
  inactiveData.push(
    ...(data
      ? data?.individuals
          .filter(individual => individual.finalLevel === null)
          .map(individual => {
            return [
              `${individual.firstName} ${individual.lastName}`,
              individual.email,
              new Date(individual.invitedAt).toLocaleDateString()
            ]
          })
      : [])
  )

  const tableOptions: any = {
    sort: 'disable',
    showRowNumber: true,
    width: '100%',
    height: '100%',
    cssClassNames: {
      headerCell: 'header-cell',
      tableCell: 'table-cell-left' // Apply custom class for left-aligned cells
    }
  }

  const timeAccuracyData: [any] = [['level', '']]
  const timeAccuracyRows: any[] = sortedIndividuals
    ? sortedIndividuals?.map(individual => {
        // const time: number = +individual.avgQuestionTaken
        const accuracy: number = Math.round((+individual.correctAnswers / +individual.answered) * 100)
        const level: number = +individual.finalLevel
        // console.log('time', individual.avgQuestionTaken, time)
        return [
          { v: level + Math.random() * 0.1, f: individual.email },
          {
            v: accuracy + Math.random(),
            f: `correct answers: ${accuracy}%, avg time per question: ${individual.avgQuestionTaken}s`
          }
        ]
      })
    : []
  timeAccuracyData.push(...timeAccuracyRows)
  console.log('timeAccuracyData', timeAccuracyData)

  /*
  // Extract the "time taken" values
  const timeTakenValues: number[] = timeAccuracyRows.map(item => item[0].v as number)

  // Calculate mean and standard deviation
  const meanTimeTaken: number = timeTakenValues.reduce((sum, value) => sum + value, 0) / timeTakenValues.length
  const stdDevTimeTaken: number = Math.sqrt(
    timeTakenValues.reduce((sum, value) => sum + Math.pow(value - meanTimeTaken, 2), 0) / timeTakenValues.length
  )

  // Calculate z-scores and filter extreme values
  const zScores: number[] = timeTakenValues.map(value => (value - meanTimeTaken) / stdDevTimeTaken)
  const threshold: number = 5 // You can adjust this threshold as needed

  const filteredData: number[][] = []
  zScores.forEach((zScore, index) => {
    if (Math.abs(zScore) <= threshold) {
      filteredData.push(timeAccuracyRows[index])
    }
  })
  timeAccuracyData.push(...filteredData)
  */

  const ticks = []
  for (let i = 1; i <= maxLevel; i++) {
    ticks.push(i)
  }
  const timeAccuracyOptions = {
    hAxis: {
      title: 'Recommended Course Level',
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF',
      minValue: 0,
      maxValue: maxLevel + 1,
      ticks: ticks
    },
    vAxis: {
      title: 'Correct answer rate (%)',
      minValue: 0,
      maxValue: 100,
      titleTextStyle: { color: '#FFF' }, // Color of the axis title
      textStyle: { color: '#FFF' }, // Color of axis labels
      gridlines: { color: 'transparent' }, // Color of gridlines
      baselineColor: '#FFF'
    },
    // series: {
    //   0: {
    //     // color: '#0000ff',
    //   },
    //   1: {
    //     // color: '#00ff00',
    //   }
    // },
    legend: {
      textStyle: { color: '#FFF' }, // Change color of the legend labels
      position: 'none'
    },
    trendlines: {
      0: {
        color: '#888888',
        tooltip: 'Custom Trendline Tooltip'
      }
    },
    height: 600,
    backgroundColor: 'transparent',
    chartArea: {
      top: 40,
      left: 100,
      bottom: 100,
      right: 100
    }
  }

  useEffect(() => {
    fetchData()
      .catch(console.error)
      .finally(() => {})
  }, [])

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: 'select',
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart()
        const selection = chart.getSelection()
        console.log('selection', selection)
        if (selection.length === 1) {
          const [selectedItem] = selection
          const dataTable = chartWrapper.getDataTable()
          const { row, column } = selectedItem
          if (row === null || column === null || column > 1) {
            return
          }
          console.log('You selected:', dataTable?.getValue(row, column)) 
          console.log('You selected:', sortedIndividuals![row].candidateId)
          // navigate(`/assessment-individual/${sortedIndividuals![row].candidateId}?v=${version}`)
        }
      }
    }
  ]
  const tableEvents: ReactGoogleChartEvent[] = [
    {
      eventName: 'select',
      callback: ({ chartWrapper }) => {
        const chart = chartWrapper.getChart()
        const selection = chart.getSelection()
        console.log('selection', selection)
        if (selection.length === 1) {
          const [selectedItem] = selection
          const { row, column } = selectedItem
          navigate(`/assessment-individual/${sortedIndividuals![row].candidateId}?v=${version}`)
        }
      }
    }
  ]

  return (
    <SidebarLayout>
      <div className="flex grow flex-col items-center gap-8 p-4 relative">
        {data === undefined && (
          <ReactLoading
            className="fixed top-[50%]"
            type={'spinningBubbles'}
            color={'#eeeeee'}
            height={'64px'}
            width={'64px'}
          />
        )}
        <button
          className="text-purple-650 absolute right-4 top-8 bg-white px-4 py-2 rounded-lg font-bold"
          onClick={() => {
            if (ref.current) {
              ref.current.save()
            }
          }}>
          Export PDF
        </button>
        <PDFExport paperSize="auto" margin="0.5cm" ref={ref}>
          <div className="bg-purple-950">
            <div className="page-title text-4xl py-16">{data?.program || 'loading'} Assessment Report</div>

            {data && (
              <div className="w-[1024px] space-y-20">
                {((assessment as Assessment)?.classifiedLevels.length ?? 0) > 0 && (
                  <div className="">
                    <div className="text-2xl text-white">Recommended Courses Overview</div>
                    <Chart chartType="PieChart" data={chartLevelData} options={options2} />
                  </div>
                )}
                <div className="p-20">
                  <div className="text-2xl text-white py-4">Participants</div>
                  <Chart chartType="Table" data={participantData} options={tableOptions} chartEvents={tableEvents} />
                  <div className="text-white py-4">
                    Finished count: {data.individuals.length}
                    <br />
                    Dropout count: {data.dropouts?.length}
                  </div>
                </div>
                {version === '1' && (
                  <div className="">
                    <div className="text-xl text-white mb-4">Answers correct rate</div>
                    <Chart chartType="ColumnChart" data={answerPercentageData} options={answerPercentageOptions} />
                  </div>
                )}
                {version === '2' && (
                  <div className="">
                    <div className="text-xl text-white mb-4">Answers correct rate</div>
                    <Chart chartType="ColumnChart" data={answerPercentageData2} options={answerPercentageOptions2} />
                  </div>
                )}
                <div className="">
                  <div className="text-xl text-white mb-4">Time spent</div>
                  <Chart chartType="Histogram" data={timeData} options={timeDataOptions} chartEvents={chartEvents} />
                </div>
                <div className="">
                  <div className="text-xl text-white mb-4">Number of questions answered</div>
                  <Chart
                    chartType="Histogram"
                    data={questionCountData}
                    options={questionCountDataOptions}
                    chartEvents={chartEvents}
                  />
                </div>

                {((assessment as Assessment)?.classifiedLevels.length ?? 0) > 0 && (
                  <div className="">
                    <div className="text-2xl text-white">Completion Ratio</div>
                    <Chart chartType="PieChart" data={chartData2} options={options2} />
                  </div>
                )}

                {((assessment as Assessment)?.classifiedLevels.length ?? 0) > 0 && (
                  <div className="p-20">
                    <div className="text-2xl text-white py-4">Inactive List</div>
                    <Chart chartType="Table" data={inactiveData} options={tableOptions} />
                  </div>
                )}

                {((assessment as Assessment)?.classifiedLevels.length ?? 0) > 0 && (
                  <div className="">
                    <div className="text-2xl text-white">Level vs Correct Answer Rate</div>
                    <Chart
                      chartType="ScatterChart"
                      data={timeAccuracyData}
                      options={timeAccuracyOptions}
                      chartEvents={chartEvents}
                    />
                  </div>
                )}

                <div className="p-10 text-white">Report date: {new Date().toLocaleDateString()}</div>
              </div>
            )}

            <div className="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-3"></div>
          </div>
        </PDFExport>
      </div>
    </SidebarLayout>
  )
}
