import { useAuth0 } from '@auth0/auth0-react'
import axios, { AxiosError } from 'axios'
import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'

const adminApiClient = axios.create({
  baseURL: process.env.REACT_APP_INSIGHT_API_URL
})

const reportApiClient = axios.create({
  baseURL: process.env.REACT_APP_REPORT_API_URL
})

const eoiApiClient = axios.create({
  baseURL: process.env.REACT_APP_EOI_API_URL
})

// console.log('INSIGHT_API_URL', process.env.REACT_APP_INSIGHT_API_URL)

type Props = {
  children: JSX.Element
}

const errorHandler = (error: AxiosError) => {
  // console.log('errorHandler', error)
  const statusCode = error.response?.status

  if (error.code === 'ERR_CANCELED') {
    return Promise.resolve()
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    // console.error(error)
  }

  // if url pattern is `/users/auth0Id/` and status code is 404, ignore the error
  if (error.config?.url?.includes('/users/auth0Id/') && statusCode === 404) {
    return Promise.resolve()
  }

  let message = error.message
  const errorData: any = error.response?.data
  if (errorData.message) {
    message = errorData.message
  }

  toast.error(message, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light'
  })

  return Promise.reject(error)
}

const AxiosClient = ({ children }: Props) => {
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    console.log('add interceptors')
    const requestInterceptors = adminApiClient.interceptors.request.use(
      async config => {
        // Do something before request is sent
        // console.log('requestInterceptors', config)
        const token = await getAccessTokenSilently()
        config.headers.Authorization = `Bearer ${token}`
        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )
    const reportRequestInterceptors = reportApiClient.interceptors.request.use(
      async config => {
        // Do something before request is sent
        // console.log('requestInterceptors', config)
        // const token = await getAccessTokenSilently()
        // config.headers.Authorization = `Bearer ${token}`
        const username = process.env.REACT_APP_REPORT_API_USERNAME
        const password = process.env.REACT_APP_REPORT_API_PASSWORD
        config.headers.Authorization = `Basic ${btoa(username + ':' + password)}`
        // config.headers.Authorization = `Basic YWRtaW46cGFzc3dvcmQ=`
        // config.headers['Authorization'] = 'Basic ' + btoa('username:password');
        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    const responseInterceptors = adminApiClient.interceptors.response.use(
      response => {
        // console.log('response from', response.request.responseURL, response.data)
        if (response.data.errors?.length) {
          console.log(response.data.errors[0].message)
          return Promise.reject(response.data.errors[0].message)
        }
        return response
      },
      error => {
        console.log('intercept response error', error.request.responseURL, error)
        Sentry.captureException(error)
        return errorHandler(error)
      }
    )
    const reportResponseInterceptors = reportApiClient.interceptors.response.use(
      response => {
        // console.log('response from', response.request.responseURL, response.data)
        if (response.data.errors?.length) {
          console.log(response.data.errors[0].message)
          return Promise.reject(response.data.errors[0].message)
        }
        return response
      },
      error => {
        console.log('intercept response error', error.request.responseURL, error)
        Sentry.captureException(error)
        return errorHandler(error)
      }
    )

    const eoiRequestInterceptors = eoiApiClient.interceptors.request.use(
      async config => {
        // Do something before request is sent
        // console.log('requestInterceptors', config)
        const token = await getAccessTokenSilently()
        config.headers.Authorization = `Bearer ${token}`
        return config
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error)
      }
    )

    const eoiResponseInterceptors = eoiApiClient.interceptors.response.use(
      response => {
        // console.log('response from', response.request.responseURL, response.data)
        if (response.data.errors?.length) {
          console.log(response.data.errors[0].message)
          return Promise.reject(response.data.errors[0].message)
        }
        return response
      },
      error => {
        console.log('intercept response error', error.request.responseURL, error)
        Sentry.captureException(error)
        return errorHandler(error)
      }
    )

    return () => {
      console.log('remove interceptors')
      adminApiClient.interceptors.response.eject(requestInterceptors)
      adminApiClient.interceptors.response.eject(responseInterceptors)
      reportApiClient.interceptors.response.eject(reportRequestInterceptors)
      reportApiClient.interceptors.response.eject(reportResponseInterceptors)
      eoiApiClient.interceptors.response.eject(eoiRequestInterceptors)
      eoiApiClient.interceptors.response.eject(eoiResponseInterceptors)
    }
  }, [getAccessTokenSilently])
  return children
}

export { AxiosClient, adminApiClient, reportApiClient, eoiApiClient }
